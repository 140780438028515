import React from "react";
import { FunctionField, Link } from "react-admin";
import {applicationNumberUrl} from "./utils";

const CitationLink = (props: any) => {

}

export const CitationsField = (props: any) => {
    return (
        <FunctionField
            {...props}
            label={"Citations"}
            render={(record: any) => <span>{(record?.citations || []).map(
                (c:any) => <div>{c.patcit ? <Link to={applicationNumberUrl(c.patcit?.["doc-number"]).url} target={"_blank"} rel="noreferrer" title={applicationNumberUrl(c.patcit?.["doc-number"]).title}>{c.patcit?.["doc-number"]}</Link> : c.nplcit?.text}</div>)
            }</span> }
        ></FunctionField>
    );
}