import {epStates, INTERNATIONAL_TRADEMARK_APPLICATION, pctStates, countries as countryList } from "../../utils/countries";

export const CASE_STATUS_PENDING = "Pending";
export const CASE_STATUS_GRANTED = "Granted";
export const CASE_STATUS_CLOSED = "Closed";

export const caseStatusList = [CASE_STATUS_PENDING, CASE_STATUS_GRANTED, CASE_STATUS_CLOSED, "Completed"];

export const isPCTOrEP = (countryCode: string) => countryCode === "pc" || countryCode === "ep";

export const parseDesignatedStates = (value: string | undefined) => {
    if (value === undefined || value === null || value === "" ) {
        return "";
    }
    const newValue = (value || "")
        .replaceAll(",", " ")
        .replaceAll("  ", " ")
        .replaceAll("  ", " ");
    // console.log("Parse:", value, newValue);
    return newValue;
}

export const validateDesignatedStates = (countryCode: string) => (value: string): string | undefined => {
    console.log(value);
    if (value === undefined || value === null || value === "" ) {
        return undefined;
    }
    const epStatesWithCHandLI = {...epStates, ch: "Switzerland", li: "Liechtenstein"}
    const countries = countryCode === INTERNATIONAL_TRADEMARK_APPLICATION ? countryList : countryCode === "ep" ? epStatesWithCHandLI : pctStates;

    const trimmed = (value || "")
        .replaceAll(",", " ")
        .replaceAll("  ", " ")
        .replaceAll("  ", " ");
    const countryCodes = trimmed.split(" ");
    const errors = countryCodes.filter((code) => !countries[code.toLowerCase()])
    console.log(errors);
    if (errors.length > 0) {
        return `Error: The following country codes were not found in the system's country list: ${errors.join(" ")}`
    } else {
        return undefined
    }
}

export const applicationNumberUrl = (applicationNumber: string) => {
    return applicationNumber?.startsWith("PCT") ?
        {
            url: `https://patentscope.wipo.int/search/en/detail.jsf?docId=${applicationNumber}`,
            title: "Open in Patentscope"
        } : {
            url: `https://worldwide.espacenet.com/patent/search?q=${applicationNumber}`,
            title: "Open in Espacenet"
        }
}
