import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, ReferenceField, SelectInput, ReferenceInput} from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import {useTenantId} from "../data/useTenantId";
import {TenantField} from "../utils/tenant";
export const NameIcon = PersonIcon;

type CaseTeam = {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
}

enum CASE_TEAM_FIELDS {
    ID = "id",
    NAME = "name",
    TENANT_ID = "tenant_id",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
    PATENT_ATTORNEY_ID = "patent_attorney_id",
    PARALEGAL_ID = "paralegal_id",
    ANNUITIES_ID = "annuities_id",
}

export const TeamList = (props: any) => {
    const tenantId = useTenantId();
    return (
        <List {...props} sort={{ field: CASE_TEAM_FIELDS.NAME, order: "ASC" }} filter={{ tenant_id: tenantId }} >
            <Datagrid>
                <TextField source={CASE_TEAM_FIELDS.NAME} />
                <TenantField label={"Client"}/>
                <ReferenceField reference="users" source={CASE_TEAM_FIELDS.PATENT_ATTORNEY_ID} label={"Patent attorney"}>
                    <TextField source={"name"}/>
                </ReferenceField>
                <ReferenceField reference="users" source={CASE_TEAM_FIELDS.PARALEGAL_ID} label={"Paralegal"}>
                    <TextField source={"name"}/>
                </ReferenceField>
                <ReferenceField reference="users" source={CASE_TEAM_FIELDS.ANNUITIES_ID} label={"Annuities"}>
                    <TextField source={"name"}/>
                </ReferenceField>
                <TextField source={CASE_TEAM_FIELDS.ID} />
                <EditButton />
            </Datagrid>
        </List>
    )
};

const CaseTeamTitle = ({ record }: { record: CaseTeam }) => {
    return <span>Post {record ? `"${record.name}"` : ''}</span>;
};

const CaseTeamForm = (props: any) => {
    const tenantId = useTenantId();

    return (
        <SimpleForm>
            <TextInput hidden source={CASE_TEAM_FIELDS.TENANT_ID} defaultValue={tenantId} style={{display: "none"}} />
            <TextInput disabled source="id" />
            <TextInput source={CASE_TEAM_FIELDS.NAME} isRequired/>
            <ReferenceInput reference="users" source={CASE_TEAM_FIELDS.PATENT_ATTORNEY_ID} isRequired>
                <SelectInput optionText={"name"} ></SelectInput>
            </ReferenceInput>
            <ReferenceInput reference="users" source={CASE_TEAM_FIELDS.PARALEGAL_ID} isRequired>
                <SelectInput optionText={"name"} ></SelectInput>
            </ReferenceInput>
            <ReferenceInput reference="users" source={CASE_TEAM_FIELDS.ANNUITIES_ID} isRequired>
                <SelectInput optionText={"name"} ></SelectInput>
            </ReferenceInput>
        </SimpleForm>
    );
}

export const CaseTeamEdit = (props: any) => {
    return (
        <Edit title={<CaseTeamTitle record={props.record} />} {...props}>
            <CaseTeamForm/>
        </Edit>
    );
};

export const CaseTeamCreate = (props: any) => {
    return (
        <Create title="Create a name" {...props} redirect={"list"}>
            <CaseTeamForm/>
        </Create>
    );
};
