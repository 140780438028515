import * as React from "react";
import { List, Datagrid, TextField, FunctionField, useRecordContext, Filter, DateInput, BooleanInput, TextInput, SelectInput, ReferenceInput, ReferenceManyField, ReferenceField, DateField, useTranslate, required, ChipField, AutocompleteInput } from 'react-admin';
import {countries, countriesOptions} from "../utils/countries";
import { Link } from "react-router-dom";
import { Button, Chip } from '@mui/material';
import {useIsAdmin} from "../auth/utils";
import {Page} from "../utils/Page";
import { FunctionComponent } from "react";
import {CountryInput} from "../utils/CountryInput";
import { CompleteTaskButton } from "./CompleteTaskButton";
import {EditTaskButton} from "./EditTaskButton";
import { CompleteSubActionButton } from "../cases/actions/CompleteSubActionButton";
import {alternateBackgroundColor, CASE_FIELDS} from "../cases/CaseList";
import {CaseRefField} from "../cases/crud/CaseRefField";
import {PrepareEmailButton} from "../cases/actions/PrepareEmailButton";
import {actionTypeOptions} from "../cases/actions/utils";
import {caseSubActionSort, SubActionsDatagrid} from "../cases/actions/SubActionsList";
import {FlagField} from "../utils/FlagField";

const QuickFilter: FunctionComponent<any> = ({ label }) => {
    const translate = useTranslate();
    return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};
const TasksFilter = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <DateInput source="internal_start_date@_gte" label="Start Date From" />
            <DateInput source="internal_start_date@_lte" label="Start Date To" />
            <DateInput source="internal_due_date@_gte" label="Service Date From" />
            <DateInput source="internal_due_date@_lte" label="Service Date To" />
            <DateInput source="due_date@_gte" label="Ex. Due Date From" />
            <DateInput source="due_date@_lte" label="Ex. Due Date To" />
            <CountryInput source={"country_code"} label={"Country"}/>
            <TextInput source="case_ref"></TextInput>
            <TextInput source="application_number"></TextInput>
            <TextInput source="catchword"></TextInput>

            {/*<TextInput source="registration_number"></TextInput>*/}
            <TextInput source="applicant"></TextInput>
            <SelectInput source="action_type" label={"Type"} choices={actionTypeOptions}/>

            {
                isAdmin &&
                <ReferenceInput reference="tenants" source="tenant_id" label="Client">
                    <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />
                </ReferenceInput>
            }

            <SelectInput label={"Status"} source={"completed_at@_is_null"} optionText={"name"} alwaysOn choices={[
                {id: false, name: "Show completed"},
                {id: true, name: "Hide completed"},
            ]} />

            <ReferenceInput reference="users" source="user_id" label={"Responsible"} alwaysOn>
                <SelectInput label={"Responsible"} optionText={"name"}/>
            </ReferenceInput>
            {/*<BooleanInput source="completed_at@_is_null" label={"Hide completed"} alwaysOn defaultValue={true} />*/}
            {/*<QuickFilter source={"completed_at@_is_null"} label={"Hide completed"} defaultValue={false}></QuickFilter>*/}
        </Filter>
    );
};

const filters = (isAdmin: boolean) =>  [
    <DateInput source="internal_start_date@_gte" label="Start Date From" />,
    <DateInput source="internal_start_date@_lte" label="Start Date To" />,
    <DateInput source="internal_due_date@_gte" label="Service Date From" />,
    <DateInput source="internal_due_date@_lte" label="Service Date To" />,
    <DateInput source="due_date@_gte" label="Ex. Due Date From" />,
    <DateInput source="due_date@_lte" label="Ex. Due Date To" />,
    <CountryInput source={"country_code"} label={"Country"}/>,
    <TextInput source="case_ref"></TextInput>,
    <TextInput source="application_number"></TextInput>,
    <TextInput source="catchword"></TextInput>,
    <TextInput source="applicant"></TextInput>,
    <SelectInput source="action_type" label={"Type"} choices={actionTypeOptions}/>,

        isAdmin ?
    <ReferenceInput reference="tenants" source="tenant_id" label="Client">
        <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />
    </ReferenceInput> : null,
    <SelectInput label={"Status"} source={"completed_at@_is_null"} optionText={"name"} alwaysOn choices={[
        {id: false, name: "Show completed"},
        {id: true, name: "Hide completed"},
    ]} />,

    <ReferenceInput reference="users" source="user_id" label={"Responsible"} alwaysOn>
        <SelectInput label={"Responsible"} optionText={"name"}/>
    </ReferenceInput>,
].filter((element) => element);

export const TaskList = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Page title={"Tasks"}>
            <List {...props}
                  sort={{ field: "due_date", order: "ASC" }}
                  filters={<TasksFilter />}
                  // filters={filters(isAdmin)}
                  exporter={false}
                  // actions={<ShowHistoryButton/>}
                  filter={{ "internal_start_date@_is_null": false }}
                  filterDefaultValues={{ "completed_at@_is_null": true }}
                  // filter={{ "internal_start_date@_is_null": false, 'completed_at@_is_null': true }}
            >
                <Datagrid
                    bulkActionButtons={false}
                    rowSx={alternateBackgroundColor}
                    expand={<TaskContextList/>}
                    expandSingle={true}
                >
                    { isAdmin && <TextField source={"tenant_name"} label={"Client"}/> }
                    <CaseRefField source={CASE_FIELDS.CASE_REF} idSource={"case_id"}></CaseRefField>
                    <FlagField label={"Country"} source={"country_code"} sx={{ textWrap: "nowrap" }}></FlagField>
                    {/*<TextField source={"applicant"} />*/}
                    <TextField source={"application_number"} />
                    {/*<TextField source={"catchword"} />*/}

                    {/*<TextField source={"code"} />*/}
                    <TextField source={"action_type"} label={"Type"} />
                    <TextField source={"action_action_name"} label={"Action"} />
                    <TextField source={"status"} />
                    <ChipField source={"action_name"} label={"Task"} />
                    <DateField source={"internal_start_date"} label={"Start date"} />
                    <DateField source={"internal_due_date"} label={"Service date"} />

                    <DateField source={"due_date"} label={"External due date"}/>
                    <TextField source={"user_name"} label={"Responsible"}/>

                    <DateField source={"completed_at"} showTime/>
                    {/*<TextField source={"completion_notes"} />*/}
                    <DateField source={"action_completed_at"} showTime/>
                    
                    <EditTaskButton />
                    {/*<CompleteTaskButton />*/}
                    <><div><TaskPrepareEmailButton /></div><div><CompleteSubActionButton /></div></>
                </Datagrid>
            </List>
        </Page>
    )
};

const TaskPrepareEmailButton = () => {
    const record = useRecordContext();
    if (!record?.email_template_id) return null;
    return <PrepareEmailButton caseId={record.case_id} emailTemplateId={record.email_template_id}/>
}

const CaseButton = () => {
    const task = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/cases/${task?.case_id}/show`}
        >
            Case details
        </Button>
    );
};

const ShowHistoryButton = () => {
    const task = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/tasks/history`}
        >
            Show history
        </Button>
    );
};

export const TaskContextList = (props: any) => {
    const caseActionRecord = useRecordContext();
    console.log(caseActionRecord);
    const fakeCaseSubAction = caseActionRecord ? {
        is_fake: true,
        action_sub_rule: {
            code: caseActionRecord.action_rule?.action_code || caseActionRecord.action_code,
            status: caseActionRecord.action_rule?.status || caseActionRecord.status,
            action_name: caseActionRecord.action_rule?.action_name || caseActionRecord.action_name,
        },
        responsible_user_id: caseActionRecord.responsible_user_id,
        case_action: {
            due_date: caseActionRecord.due_date,
        },
        completed_at: " ",
    } : {};
    return (
        <div>
            <ReferenceManyField label="Tasks in workflow" reference="case_sub_actions" target="case_action_id"
                                source={"case_action_id"}
                                sort={caseSubActionSort}>
                <SubActionsDatagrid fakeCaseSubAction={fakeCaseSubAction}/>
            </ReferenceManyField>
        </div>
    )
}
