import {dateFormat} from "../actions/Actions";
// // @ts-ignore
//import * as XLSX from 'xlsx/xlsx.mjs';

import * as XLSX from 'xlsx-js-style';
import moment from "moment";

export type ExportConfiguration = Array<{ field: string, header?: string, cellOptions?: any, width?: number }>;

export const exportConfiguration: ExportConfiguration = [
    {field: "Breeze Case Ref",},
    {field: "Breeze status",},
    {field: "Due Date Compare", width: 12, cellOptions:
            {
                t: "s",
                s: {
                    alignment: { wrapText: true }
                }
            }
    },
    {field: "Grace Date Compare", width: 12, cellOptions:
            {
                t: "s",
                s: {
                    alignment: { wrapText: true }
                }
            }
    },
    {field: "Breeze Comment", width: 70, cellOptions:
            {
                t: "s",
                s: {
                    alignment: { wrapText: true }
                }
            }
    },
    {field: "Breeze Comment + JY instructions", width: 70, cellOptions:
            {
                t: "s",
                s: {
                    alignment: { wrapText: true }
                }
            }},
    {field: "Breeze 020924", width: 30, cellOptions:
            {
                t: "s",
                s: {
                    alignment: { wrapText: true }
                }
            }},
    {field: "AQS Mgt Status",},
    {field: "", header: "Remove/keep",},
    {field: "Client Reference",},
    {field: "Family Ref",},
    {field: "AQS Ref",},
    {field: "Country Code",},
    {field: "Origin",},
    {field: "IP Type",},
    {field: "Application Type",},
    {field: "EntityStatus",},
    {field: "IP Type",},
    {field: "Application Date",},
    {field: "Application Number",},
    {field: "Publication Date",},
    {field: "Publication Number",},
    {field: "Grant Date",},
    {field: "Patent Number",},
    {field: "National Number",},
    {field: "Suspension Date",},
    {field: "Abandon Date",},
    {field: "Expiration Date",},
    {field: "Expiration Date",},
    {field: "Next Due Date",},
    {field: "AQS Last payment deadline",},
    {field: "Title",},
    {field: "Breeze Applicant",},
    {field: "Breeze Application Number", header: "Breeze App. #"},
    {field: "Breeze Registration Number", header: "Breeze Reg. #"},
];

export const downloadImportReport = (data: any[]) => {
    // const ws = XLSX.utils.json_to_sheet(data);
    const arrayOfArrays = toArrayOfArrays(data, exportConfiguration);
    const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);

    ws["!cols"] = (arrayOfArrays?.[0] || []).map((header: any) => {
            const found = exportConfiguration.find((config) => config.header === header || config.field === header);
            return ({
                wch: found?.width || data.reduce((w, r) => Math.max(w, r?.[header]?.length || 0), header.length)
            });
        }
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Breeze import");
    XLSX.writeFile(wb, `breeze_import_report_${moment().format(dateFormat)}.xlsx`);
}

const toArrayOfArrays = (data: any[], config: ExportConfiguration) => {
    const headerLines = config.map((config) => {
        return config.header || config.field;
    });
    const contentLines = data.map((item) => {
        return config.map((config) => {
            if (config.cellOptions) {
                console.log("cellOptions", config.cellOptions, item[config.field]);
            }
            let colorOverride = {};
            if (config.field === "Breeze Comment" && item?.matchingResult?.status) {
                colorOverride = {
                    s: {
                        fill: {
                            fgColor: {
                                rgb:
                                    item.matchingResult.color === "success" ? "d6f3eb" :
                                    item.matchingResult.color === "warning" ? "feebcb" :
                                    item.matchingResult.color === "error" ? "FDE6E2" :
                                        "ffffff"
                            },
                        },
                        alignment: { wrapText: true }
                    }
                }
            }

            if (config.field === "Due Date Compare" && item?.["Due Date Compare"]) {
                colorOverride = {
                    s: {
                        fill: {
                            fgColor: {
                                rgb:
                                    item?.["Due Date Match"] === "success" ? "d6f3eb" :
                                        item?.["Due Date Match"] === "warning" ? "feebcb" :
                                                "ffffff"
                            },
                        },
                        alignment: { wrapText: true }
                    }
                }
            }

            if (config.field === "Grace Date Compare" && item?.["Grace Date Compare"]) {
                colorOverride = {
                    s: {
                        fill: {
                            fgColor: {
                                rgb:
                                    item?.["Grace Date Match"] === "success" ? "d6f3eb" :
                                        item?.["Grace Date Match"] === "warning" ? "feebcb" :
                                            "ffffff"
                            },
                        },
                        alignment: { wrapText: true }
                    }
                }
            }


            return config.cellOptions ? {
                v: item[config.field] || "",
                ...config.cellOptions,
                ...colorOverride
            } : item[config.field]
                //{
                //v: item[config.field] || "",
                //t: "s",
            //}
        });
    })
    return [
        headerLines,
        ...contentLines,
    ];
}