import {useUserId} from "../../auth/utils";
import {
    Button, Create, CreateBase, email, Form,
    FormDataConsumer,
    required,
    SaveButton, SimpleForm, SimpleShowLayout,
    TabbedForm, TextField, TextInput, Toolbar, useCreate, useDataProvider, useListContext, useNotify, useRecordContext
} from "react-admin"
import {Dialog, DialogTitle, DialogContent, DialogActions, Tab, Divider} from '@mui/material';
import {Fragment, Suspense, useState} from "react";
import {LazySpinner, Spinner, SpinnerInline} from "../../utils/Spinner";
import React from "react";
import {Box, Stack} from "@mui/system";
import Mustache from 'mustache';
import SendIcon from "@mui/icons-material/Send";
import {useCompleteSubAction} from "./CompleteSubActionButton";
import moment from "moment";
import {useTenantId} from "../../data/useTenantId";

const RichTextInput = React.lazy(() =>
    import('ra-input-rich-text').then(module => ({
        default: module.RichTextInput,
    }))
);


export const PrepareEmailButton = (props: { caseId: string, emailTemplateId?: string }) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const userId = useUserId();
    const tenantId = useTenantId();
    const [open, setOpen] = useState(false);
    const [emailTemplate, setEmailTemplate] = useState<any>(null);
    const [caseData, setCaseData] = useState<any>(null);
    const [tabValue, setTabValue] = React.useState('1');
    const [create, {isLoading, error}] = useCreate('emails');
    const {data, isLoading: isCompleting, completeSubAction } = useCompleteSubAction(record.case_action_id);
    const notify = useNotify();
    const { refetch } = useListContext() || {};

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    const onClick = (e: any) => {
        e?.preventDefault();
        setOpen(true);
        console.log(record);

        dataProvider.getOne("email_templates", {
            id: props.emailTemplateId,
        }).then(res => {
            console.log(res);
            if (res.data) {
                setEmailTemplate(res.data);
            }
        });

        dataProvider.getOne("cases", {
            id: props.caseId,
        }).then(res => {
            console.log(res);
            if (res.data) {
                setCaseData(res.data);
            }
        }).catch(console.log);
    }
    const close = (e: any) => {
        e?.preventDefault();
        setOpen(false)
    };

    const transformEmail = (data: any) => {
        return {
            ...data,
            subject: Mustache.render(data?.subject || "", caseData || {}),
            body: Mustache.render(data?.body || "", caseData || {}),
        }
    };

    const markCompleted = () => {
        completeSubAction(record.id, moment().format(), "", userId).then(() => {
            notify(`Email sent and task marked completed`, { type: 'success' });
            refetch && refetch();
        });
    }

    console.log(record);

    return (
        <>
            <Button
                label={"Prepare email"}
                onClick={onClick}
            >
                <></>
            </Button>
            <Dialog open={open} onClose={close} fullWidth maxWidth={"md"}>
                <DialogTitle>Prepare and send email</DialogTitle>
                <DialogContent>
                    <Suspense fallback={<LazySpinner delay={500}/>}>
                        {
                            (emailTemplate && caseData) ?
                                <CreateBase {...props} resource={"emails"} transform={transformEmail} mutationOptions={{ onSuccess: close }}>
                                    <TabbedForm
                                        syncWithLocation={false}
                                        toolbar={
                                            <Toolbar>
                                                <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} width={"100%"}>
                                                    <SaveButton
                                                        label={"Send email"}
                                                        alwaysEnable
                                                        icon={<></>}
                                                        endIcon={<SendIcon />}
                                                    />
                                                    <SaveButton
                                                        label={"Send email and complete"}
                                                        mutationOptions={{
                                                            onSuccess: () => {
                                                                markCompleted();
                                                            }}
                                                        }
                                                        type="button"
                                                        alwaysEnable
                                                        icon={<></>}
                                                        endIcon={<SendIcon />}
                                                    />
                                                </Stack>
                                            </Toolbar>
                                        }
                                        // maxWidth={"md"}
                                        // component={Fragment}
                                        defaultValues={{
                                            subject: emailTemplate.subject,
                                            body: emailTemplate.body,
                                            type: "CASE_SUB_ACTION",
                                            sending_status: "INITIATED_BY_USER",
                                            case_sub_action_id: record.id,
                                            tenant_id: tenantId,
                                            created_by: userId,
                                        }}
                                        // onSubmit={onSubmit}
                                    >
                                        <TabbedForm.Tab label={"Email"}>
                                            <TextInput source={"recipient_email"} fullWidth
                                                       validate={[required(), email()]}></TextInput>
                                            <TextInput source={"subject"} fullWidth validate={[required()]}></TextInput>
                                            <span></span>
                                            <Stack direction={"row"}>
                                                {/*<TextField source={"subject"}></TextField>*/}
                                            </Stack>
                                            <RichTextInput source={"body"} fullWidth label={false}
                                                           validate={[required()]}></RichTextInput>
                                        </TabbedForm.Tab>
                                        <TabbedForm.Tab label={"Preview"}>
                                            <EmailPreview caseData={caseData}></EmailPreview>
                                        </TabbedForm.Tab>
                                    </TabbedForm>
                                </CreateBase>
                                :
                                <SpinnerInline/>

                        }
                    </Suspense>


                    {/*<TabContext value={tabValue}>*/}
                    {/*    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>*/}
                    {/*        <TabList onChange={handleTabChange} aria-label="Tabs">*/}
                    {/*            <Tab label="Email edit" value="1" />*/}
                    {/*            <Tab label="Preview" value="2" />*/}
                    {/*        </TabList>*/}
                    {/*    </Box>*/}
                    {/*    <TabPanel value="1">*/}
                    {/*        <TextInput source={"recipient_email"} fullWidth validate={[required(), email()]}></TextInput>*/}
                    {/*        <TextInput source={"subject"} fullWidth validate={[required()]}></TextInput>*/}
                    {/*        <span></span>*/}
                    {/*        <Stack direction={"row"}>*/}
                    {/*            /!*<TextField source={"subject"}></TextField>*!/*/}
                    {/*        </Stack>*/}
                    {/*        <RichTextInput source={"body"} fullWidth label={false} validate={[required()]}></RichTextInput>*/}
                    {/*    </TabPanel>*/}
                    {/*    <TabPanel value="2" sx={{ minHeight: "400px"}}>*/}
                    {/*        <EmailPreview></EmailPreview>*/}
                    {/*    </TabPanel>*/}
                    {/*</TabContext>*/}
                </DialogContent>
                {/*<DialogActions>*/}
                {/*    <Button onClick={close} label={"Cancel"}></Button>*/}
                {/*    <Button onClick={sendEmail} label={"Send email"}></Button>*/}
                {/*</DialogActions>*/}
            </Dialog>
        </>
    );
}

const EmailPreview = (props: { caseData: any }) => {
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => {
                return (
                    <Stack spacing={4} divider={<Divider/>} sx={{minHeight: "400px"}}>
                        <div>
                            <div><strong>Email subject:</strong></div>
                            <div
                                dangerouslySetInnerHTML={{__html: Mustache.render(formData?.subject || "", props.caseData || {})}}></div>
                        </div>
                        <div>
                            <div><strong>Email body:</strong></div>
                            <div
                                dangerouslySetInnerHTML={{__html: Mustache.render(formData?.body || "", props.caseData || {})}}></div>
                        </div>
                    </Stack>
                );
            }}
        </FormDataConsumer>

    );
}
