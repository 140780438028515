import * as React from "react";
import { BulkDeleteButton, TextField, DateField, WithRecord, Button, Datagrid, ListContextProvider, ReferenceManyField, FunctionField, List, ReferenceField } from "react-admin";
import {countries} from "../../utils/countries";
import {Page} from "../../utils/Page";


const bulkActionButtons = <>
    <BulkDeleteButton mutationMode="pessimistic" />
</>;

export const AnnuityOrders = (props: any) => {
    return (
        <Page title={"Orders"}>
            <List {...props} sort={{ field: "created_at", order: "DESC" }}>
                <Datagrid rowClick={"expand"} bulkActionButtons={bulkActionButtons} expand={
                    <div>
                        {/*<h3>Order lines</h3>*/}
                        <ReferenceManyField reference={"annuity_order_lines"} target={"annuity_order_id"}>
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="case_action.case.case_ref" label={"Case ref."}/>
                                <TextField source="case_action.case.country_code" textTransform={"uppercase"} label={"Country code"} />
                                <FunctionField
                                    label="Country"
                                    render={(record: any) => countries[record.case_action.case.country_code]}
                                />
                                <TextField source="case_action.case.application_number" label={"Application number"} />
                                <TextField source="case_action.case.registration_number" label={"Registration number"}/>
                                <TextField source="case_action.action_rule.action_name" label={"Annuity"} />
                                <TextField source="our_fee" />
                                <TextField source="official_fee" />
                                <TextField source="agent_fee" />
                                <TextField source="price" />
                                <ReferenceField source={"case_action_id"} reference={"case_actions"} label={"Due date"} link={false}>
                                    <DateField source={"due_date"}></DateField>
                                </ReferenceField>
                            </Datagrid>
                        </ReferenceManyField>
                    </div>
                }>
                    <DateField source={"created_at"} showTime />
                    <TextField source={"status"} ></TextField>
                    <ReferenceField source="created_by" reference="users">
                        <TextField source={"name"} ></TextField>
                    </ReferenceField>
                    <FunctionField
                        label="Total price"
                        render={(record: any) => `${record.price} ${record.price_currency}`}
                    />
                </Datagrid>
            </List>
        </Page>
    );
};
