import React from 'react';
import { FC, useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from "@mui/material";
import { createPortal } from 'react-dom';

export const Spinner = () => createPortal(
    <SpinnerInline />, document.body
);

export const SpinnerInline = () => (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
    >
        <CircularProgress color="inherit" />
    </Backdrop>
);



export interface LazyLoaderProps {
    delay?: number;
}

export const LazySpinner: FC<LazyLoaderProps> = ({
                                             delay = 250,
                                             ...props
                                         }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    return show ? <Spinner /> : null;
};
