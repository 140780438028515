import * as React from "react";
import { List, Datagrid, TextField, FunctionField, useRecordContext, Filter, DateInput, BooleanInput, TextInput, SelectInput, ReferenceInput, ReferenceManyField, ReferenceField, DateField, useTranslate, TopToolbar, FilterButton } from 'react-admin';
import {countries, countriesOptions} from "../utils/countries";
import { Link } from "react-router-dom";
import { Button, Chip } from '@mui/material';
import {useIsAdmin} from "../auth/utils";
import {Page} from "../utils/Page";
import { FunctionComponent } from "react";
import {CountryInput} from "../utils/CountryInput";
import { CompleteTaskButton } from "./CompleteTaskButton";
import {EditTaskButton} from "./EditTaskButton";
import {TaskCommonFields} from "./TaskCommonFields";

const TasksFilter = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <DateInput source="internal_start_date@_gte" label="Start Date From" />
            <DateInput source="internal_due_date@_lte" label="Service Date To" />
            <DateInput source="due_date@_gte" label="Ex. Due Date From" />
            <DateInput source="due_date@_lte" label="Ex. Due Date To" />
            <CountryInput source={"country_code"} label={"Country"}/>
            <TextInput source="case_ref"></TextInput>
            <TextInput source="application_number"></TextInput>
            <TextInput source="catchword"></TextInput>

            {/*<TextInput source="registration_number"></TextInput>*/}
            <TextInput source="applicant"></TextInput>
            {
                isAdmin &&
                <ReferenceInput reference="tenants" source="tenant_id" label="Client">
                    <SelectInput label={"Client"} optionText={"name"}/>
                </ReferenceInput>
            }
            <ReferenceInput reference="users" source="user_id" label={"Responsible"} alwaysOn>
                <SelectInput label={"Responsible"} optionText={"name"}/>
            </ReferenceInput>
        </Filter>
    );
};

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
        <ShowCompletedButton/>
    </TopToolbar>
);

export const TaskHistoryList = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Page title={"Tasks"}>
            <List {...props}
                  sort={{ field: "due_date", order: "ASC" }}
                  filters={<TasksFilter />}
                  exporter={false}
                  // actions={<ListActions/>}
                  filter={{ 'completed_at@_is_null': false }}
            >
                <Datagrid bulkActionButtons={false}>
                    {/*<TaskCommonFields></TaskCommonFields>*/}
                    <TextField source={"tenant_name"} label={"Client"}/>
                    <TextField source={"case_ref"} />
                    <FunctionField
                        label="Country"
                        render={(record: any) => countries[record["country_code"]]}
                    />
                    <TextField source={"applicant"} />
                    <TextField source={"application_number"} />
                    {/*<TextField source={"catchword"} />*/}

                    <TextField source={"code"} />
                    <TextField source={"status"} />
                    <TextField source={"action_name"} label={"Action"} />
                    <DateField source={"internal_start_date"} label={"Start date"} />
                    <DateField source={"internal_due_date"} label={"Service date"} />

                    <TextField source={"due_date"} label={"External due date"}/>
                    <TextField source={"user_name"} label={"Responsible"}/>

                    <TextField source={"completed_at"} />
                    <TextField source={"completion_notes"} />
                    <TextField source={"action_completed_at"} />

                    <EditTaskButton />
                </Datagrid>
            </List>
        </Page>
    )
};

const ShowCompletedButton = () => {
    const task = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/tasks`}
        >
            Show completed
        </Button>
    );
};

