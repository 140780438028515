import * as React from "react";
import { SelectInput, Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import {applicationTypeOptions, caseTypeOptions} from "../cases/CaseList";
import {actionTypeOptions} from "../cases/actions/utils";

const applicationTypeOptionsIncludingAll = [{ id: 'All', name: "All"}, ...applicationTypeOptions];

export const ActionRuleCreate = () => {
    return (
        <Create redirect={"list"}>
            <SimpleForm>
                <TextInput source="action_code" />
                <SelectInput source={"action_type"} choices={actionTypeOptions}/>
                <TextInput source="action_name" />
                <SelectInput source={"case_type"} choices={caseTypeOptions} defaultValue={caseTypeOptions[0]}/>
                <SelectInput source={"application_type"} choices={applicationTypeOptionsIncludingAll}/>
                <BooleanInput source={"application_type_inverse"} label={"Inverse application type filter (i.e NOT *application type*)"}/>
                <BooleanInput source={"active"} />
            </SimpleForm>
        </Create>
    );
};
