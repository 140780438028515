import * as React from "react";
import {
    DateField,
    TextInput,
    SingleFieldList,
    ChipField,
    ReferenceManyField,
    ReferenceField,
    TextField,
    SimpleForm,
    Create,
    ReferenceArrayInput,
    AutocompleteInput,
    useDataProvider,
} from 'react-admin';
import { useNavigate } from "react-router";
import {useTenantId} from "../data/useTenantId";


export const UserCreate = (props: any) => {
    const tenantId = useTenantId();
    const dataProvider = useDataProvider();
    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        console.log(data);
        const { tenant_id, ...createUserData } = data || {};

        const result = await dataProvider.create('users', {
            data: {
                ...createUserData,
                tenant_users: { data: [{ tenant_id }]}
            }
        });

        if (result.data) {
            navigate(`/users/${result.data.id}/show`, { replace: true });
        } else {
            console.error(result);
        }
    }

    return (
        <Create {...props}>
            <SimpleForm onSubmit={onSubmit} defaultValues={{ tenant_id: tenantId }}>
                <ReferenceArrayInput label="Client" reference="tenants" source="tenant_id" isRequired>
                    <AutocompleteInput label="Client" filterToQuery={(searchText: string) => ({name: `${searchText}`})} sx={{ minWidth: 200 }}/>
                </ReferenceArrayInput>
                <TextInput source={"name"} isRequired/>
                <TextInput source="email" isRequired/>
                {/*<TextInput source="external_auth_id" />*/}
            </SimpleForm>
        </Create>
    );
};
