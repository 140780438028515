import {AutocompleteArrayInput, Button, DataProvider,
    DateInput,
    FormDataConsumer, Link,
    ReferenceArrayInput, ReferenceInput, required, SimpleForm, TextField, TextInput, useDataProvider, useGetList,
    useGetOne, useRecordContext} from "react-admin";
import PlusIcon from '@mui/icons-material/Add';
import React, {FunctionComponent, useState} from "react";
import {ActionRule, ActionSubRule} from "../../actions/types";
import moment, {Moment} from "moment";
import {useUserId} from "../../auth/utils";
import {useTenantId} from "../../data/useTenantId";
import {PDFViewer} from "../../utils/PDFViewer";
import {noRefetch} from "../../react-admin-overrides/AppLayout";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { useNavigate } from "react-router";
import {SelectActionRuleInput} from "../../docketing/SelectActionRuleInput";
import {Box, Stack } from "@mui/system";
import { Divider } from "@mui/material";

export const CreateActionsButton: FunctionComponent = () => {
    const record = useRecordContext();
    return (
        <CreateInDialogButton resource={"case_actions"} title={`Create actions for case ${record?.case_ref}`}
                              label={"Create actions"} onClick={(e) => e?.stopPropagation()}>
            <DialogContent caseRecord={record}/>
        </CreateInDialogButton>
    );
};

const DialogContent = (props: any) => {
    const record = props.caseRecord;
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    console.log(record);

    const { data: allActionRules, total, isLoading, error } = useGetList(
        'action_rules',
        {
            pagination: { page: 1, perPage: 100 },
            filter: { action_type: "OTHER" },
            sort: { field: 'action_code', order: 'DESC' }
        }
    );

    if (!record) return null;
    console.log(record);

    const submit = (formData: any) => {
        const actionRuleIds = formData.action_rule_ids;
        const partialActionRules = actionRuleIds.map((id: string) => ({...formData.case_action?.[id], id}));

        dataProvider.createActions({
            partialActionRules,
            caseId: record.id,
        }).then((res: any) => {
            console.log(res);
            if (res.data) {
                // navigate(`/cases/${record.id}/show/actions`);
                window.location.href = `/cases/${record.id}/show/actions`;
            } else {
                console.error(res.error);
            }
        }).catch(console.error);
    }

    return (
        <Box minWidth={"500px"}>
            { !isLoading &&
                <SimpleForm onSubmit={submit} maxWidth={"600px"}>
                    {/*<TextInput disabled source={"case_id"} defaultValue={record.id}></TextInput>*/}
                    <ReferenceArrayInput
                        label="Action rules"
                        source="action_rule_ids"
                        filter={{ 'action_type': 'OTHER' }}
                        reference="action_rules">
                        <AutocompleteArrayInput
                            source={"action_rule_ids"}
                            fullWidth
                            optionText={(actionRule) => `${actionRule.action_code || ""} – ${actionRule.action_name}`}
                            resource={"action_rules"}
                            filterToQuery={(searchText: string) => ({
                                'action_name,action_code': `${searchText}`
                            })}/>
                    </ReferenceArrayInput>
                    <Stack divider={<Divider  />} spacing={5}>
                        <FormDataConsumer<{ action_rule_ids: string[] }>>
                            {({ formData, ...rest }) =>
                                formData.action_rule_ids && formData.action_rule_ids.map((id: string) => {
                                    return <ActionRuleInputFields action_rule_id={id} matched_action_rule_data={(allActionRules || []).find((ar:any) => ar.id === id)}/>
                                })}
                        </FormDataConsumer>
                    </Stack>
                </SimpleForm>}
        </Box>
    );
}

const ActionRuleInputFields: FunctionComponent<{action_rule_id: string, matched_action_rule_data: any}> = ({ action_rule_id, matched_action_rule_data }) => {
    const { data, isLoading, error, refetch } = useGetOne(
        "action_rules",
        {
            id: action_rule_id,
        },
        noRefetch
    );

    if (isLoading) return null;
    if (error || !data) {
        console.log(error, data);
        return null;
    }

    console.log(data);
    return (
        <Stack>
            <ActionRuleTitle actionRule={data} />
            {data.extract_field === "letter_date" &&
                <DateInput source={`case_action.${action_rule_id}.letter_date`} label={"Letter date"} validate={required()} defaultValue={matched_action_rule_data?.attributes?.date_of_office_action}/>
            }
            <DateInput source={`case_action.${action_rule_id}.due_date`} label={"External due date"} validate={required()} defaultValue={matched_action_rule_data?.attributes?.due_date}/>
        </Stack>
    );
}

const ActionRuleTitle = (props: any) => {
    const { actionRule } = props;
    if (!actionRule) return null;
    return <span>{`${actionRule.action_code} – ${actionRule.action_name}`}</span>
}
