import { defaultTheme, RaThemeOptions } from 'react-admin';
import indigo from '@mui/material/colors/indigo';
import pink from '@mui/material/colors/pink';
import red from '@mui/material/colors/red';
import { lighten } from '@mui/material';

const theme: RaThemeOptions = {
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        primary: pink,
        secondary: pink,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    // typography: {
    //     // Use the system font instead of the default Roboto font.
    //     fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    // },
};

export const warningLight = lighten("#fee3b5", 0.3);
export const errorLight = "#FDE6E2";
export const successLight = "#D6F3EB";

export const appTheme = defaultTheme;
// export const appTheme = theme;
