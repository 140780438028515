import {ExportConfiguration} from "../import/importReportExporter";
import {dateFormat} from "../actions/Actions";
import * as XLSX from 'xlsx-js-style';
import moment from "moment";
import {access} from "../../utils/utils";

export const exportConfiguration: ExportConfiguration = [
    {field: "case_action.case.case_ref", header: "Breeze Case Ref"},
    {field: "case_action.case.tenant_case_ref", header: "Client Case Ref"},
    {field: "case_action.case.country_code", header: "Country"},
    {field: "case_action.case.applicant", header: "Applicant"},
    {field: "case_action.case.application_number", header: "Application #"},
    {field: "case_action.case.registration_number", header: "Registration #"},
    {field: "type", header: "Type",},

    {field: "case_action.action_rule.action_name", header: "Annuity/Renewal"},
    {field: "instruction", header: "Instruction"},
    {field: "user.name", header: "Instructed by"},
    {field: "created_at", header: "Instructed at"},
    {field: "case_action.instructions_confirmed_by_payment_agent_at", header: "Confirmed by Breeze"},

];


const toArrayOfArrays = (data: any[], config: ExportConfiguration) => {
    const headerLines = config.map((config) => {
        return config.header || config.field;
    });
    const contentLines = data.map((item) => {
        return config.map((config) => {
            return access(config.field, item);
        });
    })
    return [
        headerLines,
        ...contentLines,
    ];
}

export const instructionsExportToExcel = (data: any[]) => {
    console.log("EXPORT: ", data);
    const transformedData = data.map((item) => {
        return {
            ...item,
            case_action: {
                ...item?.case_action,
                case: {
                    ...item?.case_action?.case,
                    country_code: item?.case_action?.case?.country_code?.toUpperCase()
                }
            },
            type: item?.case_action?.case?.case_ref?.startsWith("D") ? "Design" : "Patent",
        }
    });
    const arrayOfArrays = toArrayOfArrays(transformedData, exportConfiguration);
    const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);

    ws["!cols"] = (arrayOfArrays?.[0] || []).map((header: any) => {
            const found = exportConfiguration.find((config) => config.header === header || config.field === header);
            return ({
                wch: found?.width || transformedData.reduce((w, r) => Math.max(w, r?.[header]?.length || 0), header.length)
            });
        }
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Breeze confirm instructions");
    XLSX.writeFile(wb, `breeze_confirm_instructions_${moment().format(dateFormat)}.xlsx`);
}

