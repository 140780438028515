import {Datagrid, Button, DateField, DateInput, Edit, EditButton, NumberField, ReferenceField, NumberInput, SimpleForm, TextField, TextInput, DeleteButton, BulkDeleteButton, FunctionField, useRecordContext, ReferenceManyField, useDataProvider, useListContext } from "react-admin"
import { useLocation } from 'react-router';
import {Chip, Stack, Tooltip, Typography} from "@mui/material"
import React from "react";
import {useIsAdmin, useUserId} from "../../auth/utils";
import moment from "moment";
import {SubActionsList} from "../actions/SubActionsList";
import {compactTable} from "../CaseList";
import {dateFormat} from "../actions/Actions";

import {
    AnnuityAbandonedField,
    AnnuityPaidElsewhereField,
    AnnuityPaidField,
    InstructionsSentField
} from "../annuities/utils";

enum CASE_ACTION_FIELDS {
    ID = "id",
    NAME = "action_rule.action_name",
    DUE_DATE = "due_date",
    PRICE = "price",
    PRICE_CURRENCY_CODE = "price_currency_code",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
}

export const NextSubAction = () => {
    const record = useRecordContext();
    const { isLoading, data } = useListContext();
    if (isLoading || record.type === "ANNUITY" || data.length === 0) return null;

    const sorted = data
        .sort((a, b) => a.action_sub_rule.code.localeCompare(b.action_sub_rule.code));

    const nonCompleted = sorted
        .filter((sub_action) => sub_action.completed_at === null);


    if (nonCompleted.length === 0) {
        const last = sorted[sorted.length - 1];
        return <Chip color={"primary"} label={`Completed, ${moment(last.completed_at).format(dateFormat)}`}></Chip>;
    }

    const next = nonCompleted[0];
    return (
        <Chip label={next.action_sub_rule?.action_name}/>
    );
};

export const CaseActionsList = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Datagrid
            {...props}
            bulkActionButtons={isAdmin ? <BulkDeleteButton mutationMode="pessimistic" /> : false}
            rowClick={"expand"}
            expand={isAdmin ? <SubActionsList/> : undefined}
            expandSingle
            sx={compactTable}
        >
            <FunctionField
                label="Name"
                render={(record: any) => record?.action_country_rule?.override_action_name ? record?.action_country_rule?.override_action_name : record?.action_rule?.action_name }
            />
            <FunctionField
                label="Due date"
                render={(record: any) => record?.due_date }
            />
            <DateField source={"with_fine_due_date"}></DateField>
            { isAdmin &&
                <ReferenceField source={"case_document_id"} reference={"case_documents"}>
                    <TextField source={"title"} />
                </ReferenceField>
            }

            <ReferenceManyField target={"case_action_id"} reference={"case_sub_actions"} label={"Next task"}>
                <NextSubAction />
            </ReferenceManyField>

            { isAdmin && <EditButton />}
            { isAdmin && <CaseActionsDelete />}
        </Datagrid>
    )
}


const inactivatePaid = (record: any, index: number) => ({
    backgroundColor: record?.annuity_payment_paid ? '#e1e1e1' : 'white',
});

export const AnnuityCaseActionsList = (props: any) => {
    const isAdmin = useIsAdmin();
    const { data } = useListContext();
    return (
        <Datagrid
            {...props}
            bulkActionButtons={isAdmin ? <BulkDeleteButton mutationMode="pessimistic" /> : false}
            // rowClick={"expand"}
            rowSx={inactivatePaid}
            // expand={isAdmin ? <SubActionsList/> : undefined}
            expandSingle
            sx={compactTable}
        >
            { isAdmin && <DateField source={"created_at"} showTime></DateField> }
            <FunctionField
                label="Name"
                render={(record: any) => record?.action_country_rule?.override_action_name ? record?.action_country_rule?.override_action_name : record?.action_rule?.action_name }
            />
            <FunctionField
                label="Due date"
                render={(record: any) => record?.inactivated_at ? `Inactivated` : record?.due_date }
            />
            <DateField source={"with_fine_due_date"}></DateField>
            <FunctionField
                label="Status"
                render={(record: any) =>
                    record.annuity_payment_paid ? <AnnuityPaidField/> :
                    record.annuity_payment_instructions_sent ? <InstructionsSentField/> :
                    record.annuity_abandoned_by ? <AnnuityAbandonedField/> :
                    record.annuity_paid_elsewhere_by ? <AnnuityPaidElsewhereField/> : null
            }/>
            { isAdmin && <EditButton />}
            { isAdmin && <CaseActionsDelete />}
        </Datagrid>
    )
}

export const CaseActionsDelete = (props: any) => {
    const location = useLocation();
    const case_id =
        location.state?.record?.case_id
            ? location.state.record.case_id
            : undefined;
    const redirect = case_id ? `/cases/${case_id}/show/actions` : false;
    return (
        <DeleteButton mutationMode="pessimistic" redirect={redirect}></DeleteButton>
    );
};

export const CaseActionsEdit = (props: any) => {
    const location = useLocation();
    const case_id =
        location.state?.record?.case_id
            ? location.state.record.case_id
            : undefined;
    console.log(location, case_id);
    const redirect = case_id ? `/cases/${case_id}/show/actions` : false;
    return (
        <Edit title={props.record?.name || ""}  {...props} redirect={redirect}>
            <SimpleForm>
                <TextInput source={CASE_ACTION_FIELDS.NAME} disabled label={"Name"}/>
                <DateInput source={CASE_ACTION_FIELDS.DUE_DATE}/>
                {/*<NumberInput source={CASE_ACTION_FIELDS.PRICE} />*/}
                {/*<TextInput source={CASE_ACTION_FIELDS.PRICE_CURRENCY_CODE} />*/}
            </SimpleForm>
        </Edit>
    );
};