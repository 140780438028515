import { Box } from "@mui/system";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import React from "react";
import {Button, DateInput, DateTimeInput, Link, ReferenceInput,
    SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, useRecordContext } from "react-admin";
import moment from "moment";
import {useUserId} from "../auth/utils";

export const EditTaskButton = () => {
    const task = useRecordContext();
    return (
        <EditInDialogButton resource={"case_sub_actions"} id={task.id}  title={"Edit task"}
                              label={"Edit"} fullWidth mutationMode={"pessimistic"}>
            <Box sx={{minWidth: "600px"}}>
                <SimpleForm>
                    <ReferenceInput reference="users" source="responsible_user_id" label={"Responsible"}>
                        <SelectInput label={"Responsible"} optionText={"name"}/>
                    </ReferenceInput>
                    <DateInput source={"internal_start_date"} label={"Start date"}></DateInput>
                    <DateInput source={"internal_due_date"} label={"Service date"}></DateInput>
                    {
                        task.completed_at &&
                        <TextInput source={"completion_notes"} multiline fullWidth></TextInput>
                    }
                </SimpleForm>
            </Box>
        </EditInDialogButton>
    );
};
