import {useUserId} from "../../auth/utils";
import {internalDueDate, internalStartDate} from "../../common/common-utils";
import {caseSubActionSort} from "./SubActionsList";
import {
    Button,
    DateTimeInput,
    SaveButton,
    TextInput,
    SimpleForm,
    Toolbar,
    useDataProvider,
    useRecordContext,
    useListContext,
    useNotify,
    BooleanInput
} from "react-admin"
import moment from "moment";
import {LazySpinner, SpinnerInline} from "../../utils/Spinner";
import {Box, Dialog, DialogContent, DialogTitle} from "@mui/material";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';

export const useCompleteSubAction = (caseActionId: string) => {
    const dataProvider = useDataProvider();
    const data = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    function completeSubAction(subActionId: any, completedAt: string, completionNotes: string, userId: string) {
        setIsLoading(true);
        return dataProvider.getList("case_sub_actions", {
            pagination: {page: 1, perPage: 100},
            sort: caseSubActionSort,
            filter: {
                case_action_id: caseActionId,
                completed_at: {
                    format: "hasura-raw-query",
                    value: {_is_null: true},
                }
            }
        }).then(res => {
            console.log(res);
            const subActions = res.data;
            const currentActionIndex = subActions.findIndex((action: any) => action.id === subActionId);
            const currentAction = subActions[currentActionIndex];
            const nextAction = subActions[currentActionIndex + 1];
            const promises = [];

            // TODO: All of this should be in a transaction

            if (nextAction) {
                const internal_start_date = internalStartDate(nextAction.action_sub_rule, moment(nextAction.case_action.due_date));
                promises.push(dataProvider.update("case_sub_actions", {
                    id: nextAction.id,
                    data: {
                        ...nextAction,
                        internal_start_date,
                        internal_due_date: internalDueDate(nextAction.action_sub_rule, moment(internal_start_date), moment(nextAction.case_action.due_date))
                    },
                    previousData: nextAction,
                }).then(res => {
                    console.log(res);
                    return res;
                }));
            }

            promises.push(dataProvider.update("case_sub_actions", {
                id: subActionId,
                data: {
                    ...currentAction,
                    completed_at: completedAt,
                    completed_by: userId,
                    completion_notes: completionNotes,
                },
                previousData: currentAction,
            }).then(res => {
                console.log(res);
                return res;
            }));

            const inactivated = currentAction?.action_sub_rule?.inactivates_action_rule;
            const allCompleted = !nextAction;

            if (inactivated || allCompleted) {
                const inactivetedData = inactivated ? {
                    inactivated_at: completedAt,
                    inactivated_by: userId,
                } : {};
                const completedData = nextAction ? {} : {
                    completed_at: completedAt,
                    completed_by: userId,
                };

                promises.push(dataProvider.update("case_actions", {
                    id: currentAction.case_action_id,
                    data: {
                        ...currentAction.case_action,
                        ...inactivetedData,
                        ...completedData
                    },
                    previousData: currentAction.case_action,
                }).then(res => {
                    console.log(res);
                    return res;
                }));
            }

            return Promise.all(promises).catch((e) => {
                setIsLoading(false);
                throw new Error(e);
            });
        })
    }

    return {data, isLoading, completeSubAction}
}

export const CompleteSubActionButton = (props: any) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const userId = useUserId();
    const [open, setOpen] = useState(false);
    const {refetch} = useListContext() || {};
    const notify = useNotify();
    const {data, isLoading, completeSubAction} = useCompleteSubAction(record.case_action_id);

    const onSubmit = (formData: any) => {
        // First fetch next sub action, then update current and next sub action
        console.log(record);
        completeSubAction(record.id, moment().format(), "", userId)
            .then(() => {
                notify(`Task successfully marked as completed`, {type: 'success'});
                refetch && refetch();
            });
    }
    const close = (e: any) => {
        e.preventDefault();
        setOpen(false)
    };

    return (
        <>
            <Button
                label={"Mark Completed"}
                onClick={() => setOpen(true)}
            >
                <></>
            </Button>
            <Dialog open={open} onClose={close} fullWidth maxWidth={"md"}>
                <DialogTitle>Mark task as completed</DialogTitle>
                <DialogContent sx={{minWidth: "600px"}}>
                    <SimpleForm
                        onSubmit={onSubmit}
                        toolbar={
                            <Toolbar>
                                {/*<SaveButton label="Mark completed" alwaysEnable />*/}
                                <LoadingButton
                                    loading={isLoading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon/>}
                                    variant="contained"
                                    type={"submit"}
                                >
                                    Mark completed
                                </LoadingButton>
                            </Toolbar>
                        }>
                        <Box width={"100%"} maxWidth={"400px"}>
                            <DateTimeInput source={"completed_at"} defaultValue={moment().format()}
                                           parse={(val: any) => moment(val).format()}></DateTimeInput>
                            <TextInput source={"completion_notes"} multiline fullWidth></TextInput>
                        </Box>
                    </SimpleForm>
                </DialogContent>
                {/*<DialogActions>*/}
                {/*    <Button onClick={close} label={"Cancel"}></Button>*/}
                {/*    <Button onClick={sendEmail} label={"Send email"}></Button>*/}
                {/*</DialogActions>*/}
            </Dialog>
        </>
    );
}