import * as React from "react";
import { Suspense } from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, RichTextField} from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import {useTenantId} from "../data/useTenantId";
import {LazySpinner, Spinner} from "../utils/Spinner";

const RichTextInput = React.lazy(() =>
    import('ra-input-rich-text').then(module => ({
        default: module.RichTextInput,
    }))
);

export type EmailTEmplateName = {
    id: string;
    created_at: string;
    updated_at: string;
    code: string;
    name: string;
    subject: string;
    body: string;
}

enum EMAIL_TEMAPLTES_FIELDS {
    ID = "id",
    TENANT_ID = "tenant_id",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
    CODE = "code",
    NAME = "name",
    SUBJECT = "subject",
    BODY = "body",
}

export const EmailTemplatesList = (props: any) => (
    <List {...props} sort={{ field: EMAIL_TEMAPLTES_FIELDS.CODE, order: "ASC" }}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={EMAIL_TEMAPLTES_FIELDS.CODE} />
            <TextField source={EMAIL_TEMAPLTES_FIELDS.NAME} />
            <TextField source={EMAIL_TEMAPLTES_FIELDS.SUBJECT} />
            <RichTextField source={EMAIL_TEMAPLTES_FIELDS.BODY} />
            <EditButton />
        </Datagrid>
    </List>
);

const EmailTemplatesTitle = ({ record }: { record: EmailTEmplateName }) => {
    return <span>Post {record ? `"${record.name}"` : ''}</span>;
};

export const EmailTemplatesEdit = (props: any) => {
    const tenantId = useTenantId();
    return (
        <Edit title={<EmailTemplatesTitle record={props.record} />} {...props}>
            <Suspense fallback={<LazySpinner delay={500} />}>

            <SimpleForm maxWidth={"sm"}>
                {/*<TextInput hidden source={EMAIL_TEMAPLTES_FIELDS.TENANT_ID} defaultValue={tenantId} style={{display: "none"}} />*/}
                <TextInput disabled source="id" />
                <TextInput source={EMAIL_TEMAPLTES_FIELDS.CODE} />
                <TextInput source={EMAIL_TEMAPLTES_FIELDS.NAME} />
                <TextInput source={EMAIL_TEMAPLTES_FIELDS.SUBJECT} fullWidth />
                {/*<TextInput source={EMAIL_TEMAPLTES_FIELDS.BODY} />*/}
                <RichTextInput source={EMAIL_TEMAPLTES_FIELDS.BODY} />
            </SimpleForm>
            </Suspense>
        </Edit>
    );
};

export const EmailTemplatesCreate = (props: any) => {
    const tenantId = useTenantId();
    return (
        <Create title="Create an email template" {...props}>
            <Suspense fallback={<LazySpinner delay={500} />}>
            <SimpleForm maxWidth={"sm"}>
                {/*<TextInput hidden source={EMAIL_TEMAPLTES_FIELDS.TENANT_ID} defaultValue={tenantId} style={{display: "none"}} />*/}
                <TextInput source={EMAIL_TEMAPLTES_FIELDS.CODE} />
                <TextInput source={EMAIL_TEMAPLTES_FIELDS.NAME} />
                <TextInput source={EMAIL_TEMAPLTES_FIELDS.SUBJECT} />
                {/*<TextInput source={EMAIL_TEMAPLTES_FIELDS.BODY} />*/}
                <RichTextInput source={EMAIL_TEMAPLTES_FIELDS.BODY} />
            </SimpleForm>
            </Suspense>
        </Create>
    );
};
