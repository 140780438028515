import * as React from "react";
import { List, Datagrid, TextField, DateField, TextInput, DateInput } from 'react-admin';

const filters = [
    <TextInput source={"currency_code"} />,
    <DateInput source="date@_gte" label="Date From" />,
    <DateInput source="date@_lte" label="Date To" />,
];

export const ExchangeRateList = (props: any) => {
    return (
        <List filters={filters} resource="exchange_rates" sort={{ field: "date,currency_code", order: "DESC,DESC" }} perPage={50}>
            <Datagrid bulkActionButtons={false}>
                <DateField source={"date"} />
                <TextField source={"currency_code"} />
                <TextField source={"currency_name"} />
                <TextField source={"exchange_rate"} />
                <DateField source={"created_at"} showTime />
            </Datagrid>
        </List>
    );
};