import { useAuth0 } from "@auth0/auth0-react"

export const useIsAdmin = () => {
    const auth0Data = useAuth0();
    // @ts-ignore
    return auth0Data ? auth0Data?.user?.["https://hasura.io/jwt/claims"]?.["x-hasura-role"]  === "admin" : false;
}

export const useUserId = () => {
    const auth0Data = useAuth0();
    // @ts-ignore
    return auth0Data?.user?.["https://hasura.io/jwt/claims"]?.["x-hasura-user-id"];
}