import React, {FunctionComponent, useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {Button, Login} from "react-admin";

const LoginPage = (props: any) => <Login {...props}><LoginForm /> </Login>;

const LoginFormC = (props: any) => {
    const { loginWithRedirect } = useAuth0();
    useEffect(() => {
        // console.log("login form rendered")
        // const location = window.location.href;
        // const url = new URL(window.location.href);
        // const { searchParams } = url ;
        // const accessToken = searchParams.get('access_token');
        // const idToken = searchParams.get('id_token');
        //
        // // If code is present, we came back from the provider
        // if (accessToken && idToken) {
        //     console.log("oauth callback received", location)
        //     userLogin({ location }, "");
        // }
        handleLogin();
    }, []);

    const handleLogin = () => {
        console.log("Is this being called at all?");
        // userLogin({}, ""); // Do not provide code, just trigger the redirection
    };

    return <><Button
        variant="contained"
        type="submit"
        color="primary"
        onClick={() => loginWithRedirect()}
    >
        <span>Login</span>
    </Button></>
}

const LoginForm = LoginFormC;

export default LoginPage;

