import * as React from 'react';
import { HorizontalMenu } from '@react-admin/ra-navigation';
import {useIsAdmin} from "../auth/utils";
import {Link, Logout, UserMenu, useUserMenu, Button, ToggleThemeButton } from 'react-admin';
import { MenuList, MenuItem, ListItemIcon, ListItemText, Tab, Menu as MUIMenu } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {ReactHTML, useState} from 'react';
import {TenantContext} from "./AppLayout";
export const Menu = () => {
    const isAdmin = useIsAdmin();
    const { tenant } = React.useContext(TenantContext) || {};
    const documents = tenant?.feature_documents || isAdmin;
    const trademarks = tenant?.feature_trademarks || isAdmin;
    const designs = tenant?.feature_designs || isAdmin;
    const other_cases = tenant?.feature_other_cases || isAdmin;
    const actions = tenant?.feature_actions || isAdmin;
    const tasks = tenant?.feature_tasks || isAdmin;

    return (
        <HorizontalMenu>
            <HorizontalMenu.Item label="Home"  value="home" to={"/"} />
            {
                isAdmin ?
                    [
                        <HorizontalMenu.Item label="Users" value="users" />,
                        <HorizontalMenu.Item label="Names" value="names" />,
                        <HorizontalMenu.Item label="Teams" value="case_teams" />,
                        <HorizontalMenu.Item label="Clients"  value="tenants" />,
                        <HorizontalMenu.Item label="Docketing"  value="docketing" />,
                        // <ToggleThemeButton />,
                    ] : []
            }
            {/*<HorizontalMenu.Item label="Cases"  value="cases" />*/}
            <HorizontalMenu.Item label="Patents"  value="cases" />
            {/*{ isAdmin ? <HorizontalMenu.Item label="Trademarks"  value="trademarks" to={"/trademarks"} /> : <></> }*/}
            { trademarks ? <HorizontalMenu.Item label="Trademarks"  value="trademarks" to={"/trademarks"} /> : <></> }
            { designs ? <HorizontalMenu.Item label="Designs"  value="designs" to={"/designs"} /> : <></> }
            {/*<HorizontalMenu.Item label="Other cases"  value="other-cases" to={"/other-cases"} />*/}
            { other_cases ? <OtherCasesMenu label="Other cases"  value="other-cases" to={"/other-cases"}></OtherCasesMenu> : <></> }
            {/*<Link label="Other cases"  value="other-cases" to={"/javascript:void(0)"}></Link>*/}
            <HorizontalMenu.Item label="Annuities"  value="annuities" />
            {/*{isAdmin ? <HorizontalMenu.Item label="Actions"  value="actions" /> : <></>}*/}
            { actions ? <HorizontalMenu.Item label="Actions"  value="actions" /> : <></> }
            {/*{isAdmin ? <HorizontalMenu.Item label="Tasks"  value="tasks" /> : <></>}*/}
            { tasks ? <HorizontalMenu.Item label="Tasks"  value="tasks" /> : <></> }
        </HorizontalMenu>
    )
};

export const CustomUserMenu = () => {
    const isAdmin = useIsAdmin();
    return (
        <UserMenu>
            <MenuList>
                { isAdmin && <CustomMenuItem to={"/action_rules"} label={"Action rules"} /> }
                { isAdmin && <CustomMenuItem to={"/country_rules"} label={"Country Rules"} />}
                { isAdmin && <CustomMenuItem to={"/prices"} label={"Prices"} />}
                { isAdmin && <CustomMenuItem to={"/exchange_rates"} label={"Exchange Rates"} />}
                { isAdmin && <CustomMenuItem to={"/annuity_orders"} label={"Orders"} />}
                { isAdmin && <CustomMenuItem to={"/instructions_received"} label={"Instructions Received"} />}
                { isAdmin && <CustomMenuItem to={"/email_templates"} label={"Email templates"} />}
                { isAdmin && <CustomMenuItem to={"/legal_event_country_codes"} label={"Legal Event Codes"} />}
                { isAdmin && <CustomMenuItem to={"/docketing_test_cases"} label={"Docketing Test Cases"} />}
                { isAdmin && <CustomMenuItem to={"/ep_validation_country_prices"} label={"EP Validation Prices"} />}
                { isAdmin && <CustomMenuItem to={"/pct_country_prices"} label={"PCT Prices"} />}
                { isAdmin && <CustomMenuItem to={"/import_sessions"} label={"Import Sessions"} />}
                { isAdmin && <CustomMenuItem to={"/import-from-file"} label={"Import from file"} />}
                { isAdmin && <CustomMenuItem to={"/annuity_cost_forecasts"} label={"Annuity Cost Forecast"} />}
                <Logout />
            </MenuList>
        </UserMenu>
    )
};

const CustomMenuItem: React.FunctionComponent<{ to: string, label: string }> = React.forwardRef(({ to, label }, ref) => {
    const { onClose } = useUserMenu();
    return (
        <MenuItem
            ref={ref}
            sx={{ color: 'text.secondary' }}
            to={to}
            component={Link}
            onClick={onClose}
        >
            <ListItemText>{label}</ListItemText>
        </MenuItem>
    );
});

const OtherCasesMenu =  (props: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
            <HorizontalMenu.Item
                value={"other-cases"}
                component={Link}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                // @ts-ignore
                onClick={handleClick}
                color="inherit"
                label={"Other Cases"}
                to={"javascript:void(0)"}
            >
            </HorizontalMenu.Item>
            <MUIMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {/*<MenuItem onClick={handleClose} to={"/other-cases/design"} component={Link}>Design</MenuItem>*/}
                <MenuItem onClick={handleClose} to={"/other-cases/infringement"} component={Link}>Infringements</MenuItem>
                <MenuItem onClick={handleClose} to={"/other-cases/opposition"} component={Link}>Oppositions</MenuItem>
            </MUIMenu>
    </>
    );
}

