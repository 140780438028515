import {useFetchEPOPatentFamily} from "../import/useFetchEPOPatentFamily";
import {Spinner} from "../../utils/Spinner";
import {capitalizeFirstLetter, dateFormat} from "../actions/Actions";
import {FlagField} from "../../utils/FlagField";
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import {
    BooleanInput,
    ChipField,
    Datagrid,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    useRecordContext,
    WithRecord,
    FormDataConsumer,
    WrapperField,
    useGetOne,
    ReferenceInput,
    AutocompleteInput
} from "react-admin";
import {useEffect} from "react";
import {CASE_FIELDS} from "../CaseList";
import {useForm} from "react-hook-form";
import moment from "moment";
import {Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Box, Stack, Typography} from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {CaseStatusField, FilingDateField} from "./CaseShow";
import {ApplicantNameInput} from "./ApplicantNameInput";
import {noRefetch} from "../../react-admin-overrides/AppLayout";

export const SyncWithEPOButton = () => {
    return (
        <EditInDialogButton title={"Sync with EPO"} label={"Sync with EPO"} icon={<></>} fullWidth
                            mutationMode={"pessimistic"} maxWidth={"lg"}>
            <SyncWithEPODialog></SyncWithEPODialog>
        </EditInDialogButton>
    );
};
const SyncWithEPODialog = () => {
    const record = useRecordContext();
    const {
        data,
        rawXml,
        error,
        loading,
        makeApiCall: fetchPatentFamily,
        resetState: resetSearchResultsState
    } = useFetchEPOPatentFamily(0);

    const isUSA = record?.country_code?.toUpperCase() === "US" ?
        {
            year: moment(record?.international_filing_date || record?.filing_date).format("YYYY"),
            fieldValue: record?.international_filing_date ? record?.international_filing_date : record?.filing_date,
            field: record?.international_filing_date ? "International filing date" : "Filing date"
        } : {};
    const searchString =
        isUSA ?
            `${record?.country_code?.toUpperCase()}${isUSA?.year || ""}${record?.application_number}` :
            `${record?.country_code?.toUpperCase()}${record?.application_number}`;

    useEffect(() => {
        fetchPatentFamily(searchString, false);
    }, [record?.country_code, record?.application_number]);

    console.log("data", data);
    console.log("case", record);
    console.log("error", error);

    const epoData = data?.[0];
    const transformedEpoData = comparingObjectFromEpoData(epoData);
    const changedFieldsList = changedFields(record, transformedEpoData);
    return (
        <Box onClick={(e: any) => e?.stopPropagation()}>
            {
                loading && <Spinner/>
            }
            {
                error && error.length > 0 &&
                <Box paddingX={2}>
                    {error?.[0]?.cause?.status === 404 ?
                        <Alert severity="error" sx={{marginBottom: 2, maxWidth: "sm"}}>
                            <AlertTitle>Not found</AlertTitle>
                            <Box>Application number {searchString} not found in EPO</Box>
                            <br/>
                            <Box>The application number above which is used for search in the EPO database is created
                                from the following information stored on the case:</Box>
                            <Box>Country code: {record?.country_code?.toUpperCase()}</Box>
                            <Box>Application number: {record.application_number}</Box>
                            {isUSA &&
                                <Box>{isUSA.field}: {isUSA.fieldValue ? moment(isUSA.fieldValue).format(dateFormat) : "N/A"}</Box>}

                        </Alert>

                        :

                        <Alert severity="error" sx={{marginBottom: 2}}>
                            <AlertTitle>General error</AlertTitle>
                            <Typography>Errors: <div>
                                <pre>{JSON.stringify(error, null, 2)}</pre>
                            </div></Typography>
                        </Alert>
                    }
                </Box>
            }
            {
                !loading && epoData &&
                <Box>
                    <SimpleForm
                        record={{...record, overrideConfig: {}, overrideValues: { applicant_name_id: record.applicant_name_id}}}
                        toolbar={
                            <Toolbar>
                                <SaveButton label="Save changes" type="button"
                                            alwaysEnable={changedFieldsList.length > 0} transform={(data: any) => {
                                    const overrideConfig = data["overrideConfig"];
                                    const overrideValues = data["overrideValues"];
                                    debugger;
                                    const transformed = Object.keys(overrideConfig).reduce((acc: any, key: string) => {
                                        if (overrideConfig[key]) {
                                            return {...acc, [key]: transformedEpoData[key]};
                                        }

                                        return acc;
                                    }, {});


                                    const saveObject = {...data, ...transformed};
                                    console.log("Save", data, transformed, saveObject);
                                    return saveObject
                                }}/>
                            </Toolbar>
                        }>
                        {
                            changedFieldsList.length > 0 &&
                            <Stack marginBottom={5} spacing={2}>
                                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                                    <ChipField source={"case_ref"}></ChipField>
                                    <FlagField source={"country_code"}></FlagField>
                                    <CaseStatusField></CaseStatusField>
                                    <FilingDateField></FilingDateField>
                                    <ChipField source={"application_type"}></ChipField>
                                </Stack>
                                <Box>Searching text in EPO: {searchString}</Box>
                                <Datagrid
                                    data={
                                        changedFieldsList.map((field) => ({
                                            field,
                                            field_name: capitalizeFirstLetter(field?.replaceAll("_", " ")),
                                            epo_value: transformedEpoData[field],
                                            system_value: record[field]
                                        }))
                                    }
                                    sort={{field: "field_name", order: "ASC"}}
                                    bulkActionButtons={false}
                                >
                                    <TextField source={"field_name"}/>
                                    <TextField source={"system_value"}/>
                                    <TextField source={"epo_value"}/>
                                    <WithRecord render={(record: any) => (
                                        <BooleanInput source={`overrideConfig.${record?.field}`}
                                                      label={"Override"}></BooleanInput>
                                    )}></WithRecord>
                                    <WrapperField label="New Value">
                                        <WithRecord render={(record: any) => (
                                            <FormDataConsumer>
                                                {({formData, ...rest}) => {
                                                    const overrideConfig = formData["overrideConfig"];

                                                    console.log(formData);
                                                    console.log("override0", overrideConfig);

                                                    return overrideConfig[record?.field] ?
                                                        <span>{transformedEpoData[record?.field]}</span> :
                                                        <span>No changes</span>
                                                }}
                                            </FormDataConsumer>
                                        )}></WithRecord>
                                    </WrapperField>
                                    {/*<WrapperField label={"Test 2"}>*/}
                                    {/*    <OverrideInputField></OverrideInputField>*/}
                                    {/*</WrapperField>*/}
                                </Datagrid>
                                <ApplicantUpdatedCheck epoData={epoData}></ApplicantUpdatedCheck>
                            </Stack>
                        }
                        {/*<SelectInput source={CASE_FIELDS.STATUS} choices={caseStatusList.map((s) => ({ id: s, name: s}))} fullWidth />*/}
                        {/*<TextInput source={CASE_FIELDS.REGISTRATION_DATE}></TextInput>*/}
                        {
                            changedFieldsList.length === 0 &&
                            <Alert variant="outlined" severity="info" sx={{marginBottom: 2}}>
                                <AlertTitle>No changes found</AlertTitle>
                                We compared the EPO data with the data in our system and found no differences.
                            </Alert>
                        }
                        <Accordion sx={{maxWidth: "100%"}}>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography>Show our interpretation of the EPO response</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <pre>{JSON.stringify(epoData, null, 2)}</pre>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </SimpleForm>
                </Box>
            }

        </Box>
    );
}

const comparingObjectFromEpoData = (epoData: any): { [key: string]: any } => ({
    application_type: orNull(epoData?.application_type),
    application_number: orNull(epoData?.application_number),
    filing_date: dateOrNull(epoData?.filing_date),
    international_filing_date: dateOrNull(epoData?.international_filing_date),
    country_code: orNull(epoData?.country_code?.toLowerCase()),
    invention_title: orNull(epoData?.invention_title),
    abstract: orNull(epoData?.abstract),
    status: orNull(epoData?.status),
    // priority_claims: epoData?.priorityClaims,
    [CASE_FIELDS.REGISTRATION_DATE]: dateOrNull(epoData?.registrationDate),
    [CASE_FIELDS.REGISTRATION_NUMBER]: orNull(epoData?.registrationEPONumber),
    closed_event_description: orNull(epoData?.closed_event_description),
    closed_at: dateOrNull(epoData?.closed_at),
})

const changedFields = (caseRecord: any, transformedEpoData: any) => {
    const changedFields = Object.keys(transformedEpoData).filter((key) => caseRecord[key] !== transformedEpoData[key]);
    console.log("changedFields", changedFields);
    return changedFields;
}

const ApplicantUpdatedCheck = (props: any) => {
    const record = useRecordContext();
    // const { data: existingApplicant, isLoading: isLoadingExisitingApplicant, error, refetch } = useGetOne(
    //     "names",
    //     {
    //         id: record?.applicant_name_id,
    //     },
    //     noRefetch
    // );

    const { setValue, watch, formState: formData } = useForm();
    useEffect(() => {
        const epoData = props.epoData;
        const epoMatchedIds = epoData?.matchedApplicants.map((a: any) => a?.id);
        const existingIsMatched = epoMatchedIds.includes(record?.applicant_name_id);
        console.log("Set value", epoMatchedIds[0]);
        setValue("overrideValues", {
            ...formData?.dirtyFields["overrideValues"],
            "applicant_name_id": epoMatchedIds[0]
        });
    }, []);

    const overrideValues = formData.dirtyFields["overrideValues"];
    console.log("overrideValues", overrideValues);

    // watch("overrideConfig", () => );

    // console.log(formData);
    // console.log("record", record);

    const epoData = props.epoData;

    const epoMatchedIds = epoData?.matchedApplicants.map((a: any) => a?.id);
    const existingIsMatched = epoMatchedIds.includes(record?.applicant_name_id);
    // console.log("epoMatchedIds[0]", epoMatchedIds[0]);

    return (
            <ReferenceInput source="overrideValues.applicant_name_id" reference="names" >
                <AutocompleteInput filterToQuery={(searchText: string) => ({ name: `${searchText}` })} fullWidth optionText={"name"} />
            </ReferenceInput>
        );
}

const OverrideInputField = (props: any) => {
    const { setValue, watch, formState: formData } = useForm();
    const record = useRecordContext();
    const overrideConfig = formData.dirtyFields["overrideConfig"];

    // watch("overrideConfig", () => );

    console.log(formData);
    console.log("override0", overrideConfig);

    return overrideConfig[record?.field] ?
        <TextInput source={`${record?.field}`}
                   label={"New value"}></TextInput> : <span>No changes</span>
}

const dateOrNull = (date: string) => date ? moment(date).format(dateFormat) : null;
const orNull = (value: any) => value || null;