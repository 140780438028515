import { Box, Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {FunctionComponent} from "react";

export const Page: FunctionComponent<{ title?: string, body?: string | React.ReactElement, center?: boolean }> = ({ children, title, body, center }) => {
    const centering = center ? { alignItems: "center" } : {};
    return (
        <Stack spacing={2} sx={{ marginTop: 2, ...centering }}>
            { title &&
                <Typography variant="h4" component="h1">
                    {title}
                </Typography>
            }
            { body &&
                <Typography variant="body1" component="div" sx={{ maxWidth: "640px" }}>
                    {body}
                </Typography>
            }

            {children}
        </Stack>
    );
}
