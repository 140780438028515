import * as React from "react";
import {
    List,
    Datagrid,
    DateField,
    TextField,
    EditButton,
    ShowButton,
    SingleFieldList,
    ChipField,
    ReferenceManyField,
    ReferenceField,
} from 'react-admin';


export const UserList = (props: any) => (
    <List {...props} sort={{ field: 'name', order: 'ASC' }}>
        {/*<Datagrid bulkActionButtons={false}>*/}
        <Datagrid>
            <TextField source={"name"}/>
            <TextField source="email" />
            <TextField source="id" />
            <TextField source="external_auth_id" />
            <DateField source={"created_at"} />
            <DateField source={"updated_at"} />
            <ReferenceManyField label="Clients" reference="tenant_users" target="user_id">
                <SingleFieldList linkType={false}>
                    <ReferenceField label="Client" source="tenant_id" reference="tenants">
                        <ChipField source="name" />
                    </ReferenceField>
                </SingleFieldList>
            </ReferenceManyField>
            <EditButton />
            {/*<ShowButton />*/}
        </Datagrid>
    </List>
);
