import {Button, useGetList, useListContext } from "react-admin";



export const RunTestCasesButton = (props: any) => {
    const { selectedIds, data } = useListContext();

    const onClick = () => {
        console.log(selectedIds);
        console.log(data);
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/runDocketingTestCase-background`, {
            method: 'POST',
            body: JSON.stringify({
                idArray: selectedIds,
                rules: props.rules || [],
            }),
        }).then((res) => {
            alert(`Successfully started running the selected test cases (${selectedIds.length}) in the background. 
            
            The process takes around 30 seconds`);
        });
    }

    return (
        <div>
            <Button label={"Run test cases"}  onClick={onClick} />
        </div>
    );
}