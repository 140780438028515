import * as React from "react";
import { List, Datagrid, SelectInput, BooleanInput,  NumberInput, Edit, SimpleForm, TextInput, useRecordContext, required } from 'react-admin';
import { useParams, Link } from 'react-router-dom';
import {triggerDateOptions} from "./triggerDate";
import {CountryInput} from "../utils/CountryInput";


const actionRulesPage = (actionRuleId: string | undefined) => `/action_rules/${actionRuleId}/country_rules`;
const actionCountryRulesPage = (actionRuleId: string | undefined) => `/action_country_rules`;


export const ActionCountryRuleFields = (props: any) => {
    return (
        <>
            <CountryInput source={"country_code"} label={"Country"} validate={required()}/>
            <SelectInput source={"trigger_date"} choices={triggerDateOptions.map((s) => ({ id: s, name: s}))} validate={required()}/>
            <NumberInput source="days_until_due_date" defaultValue={0} validate={required()}/>
            <NumberInput source="months_until_due_date" defaultValue={0} validate={required()}/>
            <NumberInput source="years_until_due_date" defaultValue={0} validate={required()}/>
            <BooleanInput source="end_of_month_due_date" />
            <NumberInput source="official_fee" defaultValue={0} validate={required()}/>
            <NumberInput source="official_fee_per_claim" defaultValue={0} validate={required()}/>
            <NumberInput source="official_fee_per_extra_class" defaultValue={0} validate={required()}/>
            <NumberInput source="num_classes_included" defaultValue={1} validate={required()}/>
            <TextInput source="official_fee_currency" defaultValue={"EUR"} validate={required()}/>
            <NumberInput source="agent_fee" defaultValue={0} validate={required()}/>
            <TextInput source="agent_fee_currency" defaultValue={"EUR"} validate={required()}/>
            <NumberInput source="our_fee" defaultValue={100} validate={required()}/>
            <TextInput source="our_fee_currency" defaultValue={"EUR"} validate={required()}/>
            <NumberInput source="translation_fee_per_word" defaultValue={0} validate={required()}/>
            <TextInput source="translation_fee_currency" defaultValue={"EUR"} validate={required()}/>
            <TextInput source="override_action_name" />
        </>
    );
}
export const ActionCountryRuleEdit = (props: any) => {
    const { actionRuleId, actionCountryRuleId } = useParams();
    const redirectUrl = actionRuleId ? actionRulesPage(actionRuleId) : `/country_rules`;
    const resource = actionRuleId ? "action_country_rules" : `country_rules`;
    return (
        <Edit resource={resource} id={actionCountryRuleId} redirect={redirectUrl}>
            <SimpleForm>
                <ActionCountryRuleFields/>
            </SimpleForm>
        </Edit>
    );
};
