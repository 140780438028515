import * as React from "react";
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    FileInput,
    FileField,
    useDataProvider,
    SelectInput,
    FormDataConsumer,
    RadioButtonGroupInput,
    DateInput,
    FunctionField
} from 'react-admin';
import {useMutation} from "react-query";
import DescriptionIcon from '@mui/icons-material/Description';
import {useCallback} from "react";
import {useLocation} from 'react-router';
import {useIsAdmin} from "../auth/utils";
import {capitalizeFirstLetter} from "../cases/actions/Actions";

export const DocumentIcon = DescriptionIcon;

export type Document = {
    id: string;
    title: string;
    description: string;
    created_at: string;
    updated_at: string;
}

export enum DOCUMENT_FIELDS {
    ID = "id",
    TITLE = "title",
    TYPE = "type",
    DESCRIPTION = "description",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
}

export const documentTypes = [
    "OTHER",
    "OFFICE_ACTION",
    "FORMALITY_OBJECTIONS",
    "OFFICE_ACTION_REPORT",
    "OFFICE_ACTION_DRAFT_RESPONSE",
    "OFFICE_ACTION_INSTRUCTIONS",
    "OFFICE_ACTION_RESPONSE_FILED",
    "APPLICATION_TEXT",
    "INVOICE",
    "CITATION"
].map((type) => ({ id: type, name: capitalizeFirstLetter(type.replaceAll("_", " ").toLowerCase())}))

export const DocumentList = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <List {...props} sort={{field: DOCUMENT_FIELDS.TITLE, order: "ASC"}}>
            <Datagrid>
                {isAdmin && <TextField source={DOCUMENT_FIELDS.ID}/>}
                <TextField source={DOCUMENT_FIELDS.TYPE}/>
                <TextField source={DOCUMENT_FIELDS.TITLE}/>
                <TextField source={DOCUMENT_FIELDS.DESCRIPTION}/>
                <FunctionField label={"Citation"} render={(record: any) => record[DOCUMENT_FIELDS.TYPE] === "CITATION" ?
                    record.citation_type === "PATENT" ?
                        `${record.citation_application_number || record.citation_registration_number}` :
                        `${record.citation_authors}`
                    : null
                }></FunctionField>
                <EditButton/>
            </Datagrid>
        </List>
    );
};

const DocumentTitle = ({record}: { record: Document }) => {
    return <span>Post {record ? `"${record.title}"` : ''}</span>;
};

export const DocumentEdit = (props: any) => {
    const isAdmin = useIsAdmin();
    const location = useLocation();
    console.log(location);
    return (
        <Edit title={<DocumentTitle record={props.record}/>} {...props} >
            <SimpleForm maxWidth={"sm"}>
                {isAdmin && <TextInput disabled source="id"/>}
                <SelectInput source={DOCUMENT_FIELDS.TYPE} isRequired choices={documentTypes}/>
                <TextInput source={DOCUMENT_FIELDS.TITLE} resettable fullWidth/>
                <TextInput source={DOCUMENT_FIELDS.DESCRIPTION} multiline resettable fullWidth/>
                <CitationSpecificFields/>
            </SimpleForm>
        </Edit>
    );
};

interface LocationState extends Location {
    record: {
        case_id?: string
    }
}

export const DocumentCreate = (props: any) => {
    const location = useLocation();
    const case_id =
        location.state?.record?.case_id
            ? location.state.record.case_id
            : undefined;
    const dataProvider = useDataProvider();

    const {mutate} = useMutation(
        (data: any) => dataProvider.create("case_documents", {
            data: data
        })
    );

    const redirect = case_id ? `/cases/${case_id}/show/documents` : false;
    console.log(props, location, redirect);

    const save = useCallback(
        async (values) => {
            const {files, ...rest} = values;

            console.log(files, rest);

            const response = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getUploadUrl`)
                .then(res => res.json());

            console.log(response)
            const file = files.rawFile;
            const buffer = await file.arrayBuffer();
            let blobData = new Blob([buffer], {type: 'application/pdf'})
            console.log('Uploading to: ', response.uploadURL)
            const result = await fetch(response.uploadURL, {
                method: 'PUT',
                body: blobData
            })
            console.log('Result: ', result)

            const bucket_file_name = response.Key;

            const fileParams = {
                bucket_file_name,
                original_file_name: files.title
            }
            try {
                await mutate({...values, case_id, ...fileParams});
            } catch (e) {
                const error = e as {
                    body: { errors: [] | {} }
                };
                if (error.body.errors) {
                    return error.body.errors;
                }
            }
        },
        [mutate],
    );
    return (
        <Create title="Create a document" {...props} redirect={redirect}>
            <SimpleForm onSubmit={save} maxWidth={"sm"}>
                <SelectInput source={DOCUMENT_FIELDS.TYPE} isRequired choices={documentTypes}/>
                <TextInput source={DOCUMENT_FIELDS.TITLE} resettable isRequired fullWidth/>
                <TextInput source={DOCUMENT_FIELDS.DESCRIPTION} multiline resettable fullWidth/>
                <FileInput source="files" label="Documents" isRequired multiple>
                    <FileField source="src" title="title"/>
                </FileInput>
                <CitationSpecificFields/>
            </SimpleForm>
        </Create>
    )
};

export const CitationSpecificFields = () => {
    return (
        <FormDataConsumer>
            {({ formData, ...rest }) => formData[DOCUMENT_FIELDS.TYPE] === "CITATION" && (
                <>
                    <RadioButtonGroupInput source="citation_type" defaultValue={"PATENT"} choices={[
                        { id: 'PATENT', name: 'Patent' },
                        { id: 'SCIENTIFIC', name: 'Scientific article' },
                    ]} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            formData.citation_type === "PATENT" ?
                                <>
                                    <TextInput source={"citation_application_number"} fullWidth></TextInput>
                                    <TextInput source={"citation_registration_number"} fullWidth></TextInput>
                                </> :
                                <>
                                    <TextInput source={"citation_authors"} fullWidth></TextInput>
                                    <TextInput source={"citation_publication"} fullWidth></TextInput>
                                </>
                        )}
                    </FormDataConsumer>
                    <DateInput source={"citation_publication_date"} fullWidth></DateInput>
                    <TextInput source={"citation_relevant_sections"} fullWidth></TextInput>
                    <TextInput source={"citation_reference"} fullWidth></TextInput>
                    <TextInput source={"citation_relevance_classification"} fullWidth></TextInput>
                </>
            )}
        </FormDataConsumer>
    );
}
