import * as React from "react";
import { Show, TextField, SimpleShowLayout, useListContext, Datagrid, ListContextProvider, ReferenceManyField, FunctionField, DatagridProps, WithRecord, Confirm, useRecordContext, useUpdate, useNotify, DateField, useDataProvider, Form, FormDataConsumer } from "react-admin";
import {Button, Stack, Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {countries} from "../../utils/countries";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import {useUserId} from "../../auth/utils";
import {useHasuraRequest} from "../../utils/useHasuraRequest";
import {CountryFlagField} from "../select-countries/common";
import {FlagField} from "../../utils/FlagField";
import {Flag} from "../../utils/Flag";
import {annuitiesManagementRowSx, AnnuityConclusionInput} from "./AnnuitiesManagementPeriod";
import { useFormContext } from "react-hook-form";

type AnnuityOrderLinesDatagridProps = DatagridProps & {
    price: number,
    price_currency: string,
    formData: any,
}


const AnnuityOrderLinesDatagrid = (props: AnnuityOrderLinesDatagridProps) => {
    const { data, isLoading } = useListContext();
    const { price, price_currency, formData, ...restProps } = props;
    console.log(formData, data);
    const totalPrice = (data || []).reduce((acc, record) => {
        const conclusion = formData[record?.id]?.conclusion;
        return acc + (conclusion === "Pay" ? record.price : 0);
    }, 0);
    const sumLine = {
        price: totalPrice,
        case_action: {
            case: {
                case_ref: "Sum payable",
            },
            action_rule: {}
        }
    };

    return (
        <Datagrid bulkActionButtons={false} {...restProps} data={[...(data || []), sumLine]} rowSx={annuitiesManagementRowSx(props.formData)} hover={false}>
            <TextField source="case_action.case.case_ref" label={"Case ref."}/>
            {/*<TextField source="case_action.case.country_code" textTransform={"uppercase"} label={"Country code"} />*/}
            <FunctionField
                label="Country"
                sx={{ textWrap: "nowrap" }}
                render={(record: any) => record?.case_action?.case?.country_code ?
                    <Box><Flag countryCode={record.case_action.case.country_code === "up" ? "eu" : record.case_action.case.country_code}></Flag>{"  " + countries[record.case_action.case.country_code]}</Box> : null
            }
            />
            <TextField source="case_action.case.application_number" label={"Application number"} />
            <TextField source="case_action.case.catchword" label={"Catchword"} />
            <TextField source="case_action.case.registration_number" label={"Registration number"}/>
            <TextField source="case_action.action_rule.action_name" label={"Annuity"} />
            <DateField source="case_action.due_date" label={"Due date"} />
            <FunctionField
                label="Price"
                sx={{ textWrap: "nowrap" }}
                render={(record: any) => `${record.price} ${price_currency}`}
            />
            <AnnuityConclusionInput></AnnuityConclusionInput>
        </Datagrid>
    );
}

export const AnnuityOrder = (props: any) => {
    return (
        <Show {...props} sx={{maxWidth: "xl", mx: "auto"}}>
            <SimpleShowLayout>
                <Typography variant="h5">
                    Submit instructions
                </Typography>

                <FunctionField
                render={(record: any) => (
                        <Box>
                            <Box component="p">
                                <Typography variant="body1">
                                    Please review the order and submit your payment instructions.
                                </Typography>
                            </Box>
                            <Box component="p">
                                <Typography variant="body1">
                                    <strong>What's next?</strong>
                                    <br/>
                                    Breeze IP will send you an order confirmation that confirms the payment instructions. You will also receive an invoice which has to be paid within 10 days. The annuity payments will be processed once the invoice is paid.
                                </Typography>
                            </Box>

                        </Box>
                    )}
                />
                    <WithRecord render={record => (
                        <Form record={{}}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    <ReferenceManyField reference={"annuity_order_lines"} target={"annuity_order_id"} record={record}>
                                        <AnnuityOrderLinesDatagrid price={record.price} price_currency={record.price_currency} formData={formData}/>
                                    </ReferenceManyField>
                                )}
                            </FormDataConsumer>
                        </Form>
                    )} />

                <Box>
                    <Box component="p">
                        <Typography variant="body1">
                            VAT Notice: The prices are given ex. VAT. Our service fees are subject to 25% VAT for Norwegian companies, which will be itemized separately on your invoice.
                        </Typography>
                    </Box>
                    <Box component="p">
                        <Typography variant="body1">
                            Terms: The order is subject to the previously signed terms.
                        </Typography>
                    </Box>
                </Box>

                <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ my: 2}}>
                    <CancelButton/>
                    <ConfirmPaymentInstructionsButton/>
                </Stack>
            </SimpleShowLayout>
        </Show>
    );
};

const submitOrderMutation = `
mutation submitOrderMutation($annuity_order_id: uuid!, $sent_at: timestamp!, $sent_by: uuid!) {
  update_annuity_orders_by_pk(pk_columns: {id: $annuity_order_id}, _set: {status: "SUBMITTED"}) {
    id
  }
  update_case_actions_many(updates: {
    where: {
        annuity_order_lines: { annuity_order_id: {_eq: $annuity_order_id} }
    }, 
    _set: {
        annuity_payment_instructions_sent_at: $sent_at,
        annuity_payment_instructions_sent_by: $sent_by
    }
  }) {
    affected_rows
  }
}
`

const ConfirmPaymentInstructionsButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const { loading: isLoading, data, error, makeApiCall } = useHasuraRequest();
    const isSuccess = Boolean(data);
    const userId = useUserId();
    const navigate = useNavigate();

    const handleClick = () => {
        makeApiCall({
            query: submitOrderMutation,
            variables: {
                annuity_order_id: record.id,
                sent_at: moment(),
                sent_by: userId,
            }
        });
    }

    console.log(isLoading, data, isSuccess, error);

    const handleCloseDialog = () => {
        navigate("/annuities", {replace: true});
    };

    return (
        <>
            <LoadingButton
                variant="contained"
                endIcon={<SendIcon />}
                onClick={handleClick}
                loading={isLoading}
                loadingPosition="end"
            >
                Submit instructions
            </LoadingButton>
            <Dialog
                open={isSuccess}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Payment Instructions Submitted"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your annuity instructions have been submitted successfully. We'll review the instructions and get in touch.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        Go to Annuities
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const CancelButton = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);


    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirmCancellation = () => {
        // Show spinner
        // Delete the order

        // navigate("/cases", { replace: true })
        navigate("/annuities");

        // Show success cancellation Toast

        setOpen(false);
    };
    return (
        <>
            <Button variant="text" color="error" onClick={handleClick}>Cancel</Button>
            <Confirm
                isOpen={open}
                loading={false}
                title={`Cancel`}
                content="Are you sure you want cancel the order? You can always recreate it from the Annuities page."
                cancel={"No"}
                confirm={"Yes, cancel the order"}
                onConfirm={handleConfirmCancellation}
                onClose={handleDialogClose}
            />
        </>
    );
}
