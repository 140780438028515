import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    BooleanInput
} from 'react-admin';
import {entitySizeOptions} from "../common/common-utils";

export const currencyOptions = ["NOK", "USD"].map((currency) => ({id: currency, name: currency}));

export const TenantCreate = (props: any) => {
    return (
        <Create title="Create a client" {...props} >
            <SimpleForm>
                <TextInput source={"name"} />
                <TextInput source={"logo_url"} />
                <NumberInput source={"admin_fee_factor"} defaultValue={0.05} />
                <NumberInput source={"next_case_ref_serial_number"} />
                <BooleanInput source={"auto_annuity"} />
                <BooleanInput source={"calculate_annuity_costs"} />
                <TextInput source={"products"} label={"Products options (comma separated)"} />
                <TextInput source={"departments"} label={"Department options (comma separated)"} />
                <SelectInput source={"annuity_canada_entity_size"} choices={entitySizeOptions} />
                <SelectInput source={"annuity_usa_entity_size"} choices={entitySizeOptions} />
                <NumberInput source={"annuity_override_our_fee"} />
                <TextInput source={"annuity_override_our_fee_currency"} />
                <SelectInput source={"currency_code"} choices={currencyOptions} required />
                <BooleanInput source={"is_agent"} />
                <BooleanInput source={"feature_documents"} defaultValue={true} defaultChecked />
                <BooleanInput source={"feature_trademarks"} defaultValue={true} defaultChecked />
                <BooleanInput source={"feature_designs"} defaultValue={true} defaultChecked />
                <BooleanInput source={"feature_other_cases"} defaultValue={true} defaultChecked />
                <BooleanInput source={"feature_actions"} defaultValue={true} defaultChecked />
                <BooleanInput source={"feature_tasks"} defaultValue={true} defaultChecked />
            </SimpleForm>
        </Create>
    );
};
