import {useIsAdmin} from "../../auth/utils";
import {ANNUITY} from "../actions/utils";
import {CaseActionsList} from "../crud/CaseActionsList";
import {Page} from "../../utils/Page";
import {
    alternateBackgroundColor,
    CASE_FIELDS,
    CaseActionsButton,
    CaseDocumentsButton, CasesBulkRecreateActionsButton,
    compactTable,
    PatentsDatagrid
} from "../CaseList";
import {TenantField} from "../../utils/tenant";
import {countries} from "../../utils/countries";
import {CopyCaseButton} from "../CopyCaseButton";
import {
    AutocompleteInput, BulkDeleteButton, Button,
    CreateButton, Datagrid,
    DatagridConfigurable, DateField, EditButton, ExportButton, Filter, FilterButton, FunctionField,
    Link, List, Pagination, ReferenceField, ReferenceInput, ReferenceManyField,
    SelectColumnsButton,
    ShowButton, TextField, TextInput, TopToolbar, useRecordContext, useResourceContext, WrapperField } from "react-admin";
import {CountryInput} from "../../utils/CountryInput";
import {DepartmentInput, ProductInput} from "../crud/CaseInputFields";
import {CaseRefField} from "../crud/CaseRefField";
import {ApplicationNumberField} from "../crud/AppplicationNumberField";
import {dateFormat} from "../actions/Actions";
import {CreateActionsButton} from "../actions/CreateActionsButton";
import {RecreateActionsButton} from "../crud/CaseShow";
import moment from "moment";
import {Box, Chip, Stack, Tooltip } from "@mui/material";

const bulkActionButtons: React.ReactElement = <>
    <CasesBulkRecreateActionsButton/>
    <BulkDeleteButton mutationMode="pessimistic"/>
</>;

const OtherCasesFilter = (props: any) => (
    <Filter {...props}>
        <CountryInput source={"country_code"} label={"Country"}/>
        <TextInput source="case_ref"></TextInput>
        <TextInput source="tenant_case_ref" label={"Client ref"}></TextInput>
        <TextInput source="application_number"></TextInput>
        <TextInput source="catchword"></TextInput>
        <TextInput source="registration_number"></TextInput>
        <TextInput source="applicant"></TextInput>
        <ProductInput source={"product"}></ProductInput>
        <DepartmentInput source={"department"}></DepartmentInput>
        <ReferenceInput reference="tenants" source="tenant_id" label="Client">
            <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />
        </ReferenceInput>
    </Filter>
);

const OtherCasesActions = () => {
    const isAdmin = useIsAdmin();
    const commonFilters = [
    <CountryInput label={"Country"} source={"country_code"}/>,
        <TextInput source="case_ref"></TextInput>,
        <TextInput source="tenant_case_ref" label={"Client ref"}></TextInput>,
        <TextInput source="application_number"></TextInput>,
        <TextInput source="registration_number"></TextInput>,
        <TextInput source="applicant"></TextInput>,
        <ProductInput source={"product"}></ProductInput>,
    ];

    const filters = isAdmin ? [
        ...commonFilters,
        <ReferenceInput reference="tenants" source="tenant_id" label="Client">
            <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />
        </ReferenceInput>,
    ] : commonFilters;
    return (
        <TopToolbar>
            <FilterButton filters={filters}/>
            {isAdmin && <CreateButton/>}
            <SelectColumnsButton />
            {isAdmin && <ExportButton/>}
        </TopToolbar>
    );
};
export const DesignCasesList = (props: any) => {
    const isAdmin = useIsAdmin();
    const config = {
        bulkActions: isAdmin ? bulkActionButtons : false as false,
        rowClick: isAdmin ? "expand" : undefined,
        expand: isAdmin ?
            <div>
                <h3>Actions</h3>
                <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                    sort={{field: "due_date", order: "ASC"}} filter={{ "action_rule#action_type@_neq": ANNUITY }}>
                    <CaseActionsList />
                </ReferenceManyField>
            </div> : undefined
    }

    return (
        <Page title={"Design cases"} body={<>
        </>}>
            <List {...props}
                  resource={"cases"}
                  sort={{field: "case_ref", order: "ASC"}}
                  filter={{ "case_type@_eq": "D" }}
                  filters={<OtherCasesFilter/>}
                  actions={<OtherCasesActions/>}
                  perPage={100}
                  pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                  storeKey={"design"}
            >
                <DatagridConfigurable omit={["id", "primary_agent_id", "local_agent_id", "created_at", "expiration_date"]} bulkActionButtons={config.bulkActions} rowClick={config.rowClick} rowSx={alternateBackgroundColor} sx={compactTable}
                                      expand={config.expand}>
                    {isAdmin && <TenantField label={"Client"}/>}

                    <CaseRefField source={CASE_FIELDS.CASE_REF}></CaseRefField>
                    <TextField source={"tenant_case_ref"} label={"Client ref"}></TextField>
                    <TextField source={CASE_FIELDS.APPLICANT}/>
                    <TextField source={"entity_size"}/>
                    <TextField source={"id"}/>
                    <DateField source={"created_at"} showTime/>
                    <FunctionField
                        label="Country"
                        render={(record: any) => countries[record[CASE_FIELDS.COUNTRY_CODE]]}
                    />
                    <ApplicationNumberField source={CASE_FIELDS.APPLICATION_NUMBER}/>
                    <TextField source={CASE_FIELDS.CATCHWORD}/>
                    <FunctionField
                        label={"Title"}
                        source={CASE_FIELDS.INVENTION_TITLE}
                        render={(record: any) => <span title={record?.invention_title}>{record?.invention_title?.length > 50 ? record?.invention_title?.substr(0, 50) + "..." : record?.invention_title}</span> }
                    ></FunctionField>
                    <TextField source={CASE_FIELDS.APPLICATION_TYPE}/>
                    {/*<TextField source={CASE_FIELDS.BASED_ON_PCT}/>*/}
                    <DateField source={CASE_FIELDS.FILING_DATE}/>
                    <DateField source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}/>
                    <DateField source={CASE_FIELDS.NATIONAL_FILING_DATE}/>
                    <DateField source={CASE_FIELDS.REGISTRATION_DATE}/>
                    <TextField source={CASE_FIELDS.REGISTRATION_NUMBER}/>
                    <DateField source={"expiration_date"}  />
                    <TextField source={CASE_FIELDS.STATUS}/>

                    <DateField source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE}/>
                    <TextField source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER}/>
                    <TextField source={CASE_FIELDS.PRODUCT}/>
                    <TextField source={CASE_FIELDS.DEPARTMENT}/>
                    <ReferenceField reference="names" source={"primary_agent_id"} label={"Agent"}>
                        <TextField source={"name"}/>
                    </ReferenceField>
                    <ReferenceField reference="names" source={"local_agent_id"} label={"Local Agent"}>
                        <TextField source={"name"}/>
                    </ReferenceField>
                    <FunctionField
                        label="Priority Country"
                        render={(record: any) => record[CASE_FIELDS.PRIORITY_COUNTRY_CODE]?.toUpperCase()}
                    />

                    <FunctionField
                        label="Next Renewal"
                        render={(record: any) => {
                            const nextRenewal = record["next_maintenance_case_action"]?.[0];
                            const dueDate = nextRenewal?.due_date;
                            const graceDate = nextRenewal?.with_fine_due_date;

                            const isGrace = graceDate ?
                                !!moment(dueDate).isBefore(moment()) : false;

                            return isGrace ?
                                <Chip size={"small"} label={
                                    <Tooltip title={
                                        <Stack sx={{ fontSize: "1rem", padding: 2 }} spacing={1}>
                                            <Box>The original due date ({dueDate ? moment(dueDate).format(dateFormat) : "N/A"}) has passed.</Box>
                                            {
                                                record?.effective_due_date ?
                                                    <Box>The grace due date ({ graceDate ? moment(graceDate).format(dateFormat) : "N/A"}) shown here is the absolute last day for paying the annuity/renewal.</Box> :
                                                    <Box>It looks like the system is missing the grace period due date. Please contact us at post@breezeip.com and we will look into it promptly</Box>
                                            }

                                        </Stack>
                                    }>
                                        <Box>Grace: {graceDate ? <DateField source={"next_maintenance_case_action.[0].with_fine_due_date"}></DateField> : "N/A"} </Box>
                                    </Tooltip>
                                } />
                                :
                                <DateField source={"next_maintenance_case_action.[0].due_date"} label={"Next renewal"}></DateField>;
                        }}
                    />
                    <WrapperField label="Actions">
                        <EditButton/>
                        <ShowButton label={"Details"}/>
                        <CaseActionsButton label={"Actions"}/>
                        <CaseDocumentsButton />
                        {isAdmin && <RecreateActionsButton />}
                    </WrapperField>
                </DatagridConfigurable>
            </List>
        </Page>
    );
};
