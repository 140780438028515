import {Auth0ContextInterface} from "@auth0/auth0-react/src/auth0-context";
import { addRefreshAuthToAuthProvider } from "react-admin";
const auth0AuthProvider = ({
                               isAuthenticated,
                               loginWithRedirect,
                               handleRedirectCallback,
                               logout,
                               user,
    getAccessTokenSilently,
    getIdTokenClaims,
                           }: Auth0ContextInterface<any>) => ({
    // called when the user attempts to log in
    login: async (url: any) => {
        console.log("before login", url);
        // if (typeof url === 'undefined') {
        //     return loginWithRedirect()
        // }
        // return handleRedirectCallback(url.location);

        await loginWithRedirect();
        console.log("after login")
    },
    // called when the user clicks on the logout button
    logout: () => {
        console.log("AUTH0 LOGOUT", isAuthenticated);
        if (isAuthenticated) { // need to check for this as react-admin calls logout in case checkAuth failed
            logout({
                logoutParams: {
                    returnTo: window.location.origin,
                    federated: true // have to be enabled to invalidate refresh token
                }
            });

            return new Promise(resolve => setTimeout(resolve, 5000)).then(() => {
            })
        }

        try {
            //const res = logout({returnTo: window.location.origin});
            //console.log(res);
        } catch (error: any) {
            console.log("error", error)
        }
        //console.log("logout", res);
        console.log("logout");
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: (error: any) => {
        console.log("Error", error);
        if (error?.statusCode === 401 || error?.statusCode === 403) {
            return loginWithRedirect();
        }

        const errorCode = error?.body?.graphQLErrors?.[0]?.extensions?.code;
        const jwtError = errorCode === "invalid-jwt";
        console.log("Checking if Hasura API error has to do with authentication. Status=" + errorCode, error);
        if (jwtError) {
            // Maybe we have to call getAccessTokenSilently() here?
            return Promise.resolve();
            // return getAccessTokenSilently({ cacheMode: "off" }).then(accessToken => console.log(accessToken));
        }


        return Promise.resolve();
    },

    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        console.log("Check auth");
        return isAuthenticated ? Promise.resolve() : getAccessTokenSilently().then(accessToken => console.log(accessToken))
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(''),
    getIdentity: () =>
        Promise.resolve({
            id: user.id,
            fullName: user.name,
            avatar: user.picture,
        }),
});

export default auth0AuthProvider;
// export default addRefreshAuthToAuthProvider(auth0AuthProvider);
