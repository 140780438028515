import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const CloseDialogIconButton = (props: { onClose: (e: any) => void }) => {


    return (
        <IconButton
            aria-label="close"
            onClick={props.onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
    );
}