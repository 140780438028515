import React, {useState} from "react";
import { List, BulkDeleteButton, FunctionField, TextField, useRecordContext, useDataProvider, useListContext, BooleanField, SelectInput, TextInput, NumberInput, BooleanInput, Button, useGetList } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useParams, Link } from 'react-router-dom';
import {countries, countriesOptions} from "../utils/countries";
import {
    DeleteWithConfirmIconButton,
    EditableDatagrid,
    EditRowButton,
    RowForm,
    useRowContext
} from "@react-admin/ra-editable-datagrid";
import {triggerDateOptions} from "./triggerDate";
import { Button as MUIButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Backdrop, CircularProgress, Checkbox, FormControlLabel, FormGroup, FormControl, Autocomplete, TextField as MUITextField } from '@mui/material';
import {PCTBased} from "../cases/CaseList";


const ActionNameInput = (props: any) => {
    const { data, isLoading } = useGetList('action_rules');
    const choices = (data || [])
        .sort((a, b) => a?.action_code?.localeCompare(b?.action_code))
        .map(actionRule => ({ ...actionRule, displayName: `${actionRule.action_name} (${actionRule.action_code || ""})`}));
    return (
        <SelectInput
            {...props}
            source="action_rule_id"
            choices={choices}
            optionText="displayName"
            optionValue="id"
            isLoading={isLoading}
        />
    );
}

const filters = [
    // <SelectInput source={"action_rule#application_type"} choices={[{id: PCTBased, name: PCTBased}]} label={"Application type"} alwaysOn sx={{marginBottom: 6}}/>,
    <ActionNameInput label={"Action"} alwaysOn sx={{marginBottom: 6}}/>,
];

const BulkActionButtons = () => (
    <>
        <BulkDeleteButton mutationMode="pessimistic" />
    </>
);

export const PricesList = (props: any) => {

    return (
        <>
            <p></p>
            <List filters={filters} perPage={50}
                  filter={{ "action_rule#application_type_inverse": false, "action_rule#application_type": PCTBased }}
                  sort={{ field: "country_code", order: "ASC" }} resource={"action_country_rules"}>
                <EditableDatagrid
                    mutationMode="undoable"
                    bulkActionButtons={false}
                    createForm={<CountryRuleForm />}
                    editForm={<CountryRuleForm />}
                    rowClick="edit"
                    actions={<CustomActions/>}
                >
                    <FunctionField
                        label="Country"
                        render={(record: any) => countries[record["country_code"]]}
                    />
                    <TextField source={"official_fee"} />
                    <TextField source={"official_fee_currency"} />
                    <TextField source={"agent_fee"} />
                    <TextField source={"agent_fee_currency"} />
                    <TextField source={"our_fee"} />
                    <TextField source={"our_fee_currency"} />
                    <TextField source={"action_rule.action_code"} label={"Action code"} />
                    <TextField source={"action_rule.action_type"} label={"Action type"}/>
                    <TextField source={"action_rule.action_name"} label={"Action name"}/>
                    <FunctionField
                        label="Application type"
                        render={(record: any) => `${record.action_rule?.application_type_inverse ? "NOT " : ""} ${record.action_rule.application_type}`}
                    />
                </EditableDatagrid>
            </List>
        </>
    );
};

const CustomActions = () => {
    const record = useRecordContext();
    const body = `Are you sure you want to delete country rule for action '${record.action_rule?.action_name}' for ${countries[record.country_code]} (${record.country_code})`
    return (
        <>
            <EditRowButton />
            <DeleteWithConfirmIconButton mutationMode="undoable" confirmContent={body} confirmTitle={`Delete country rule`} />
        </>
    )
};

const CountryRuleForm = () => (
    <RowForm>
        <FunctionField
            label="Country"
            render={(record: any) => countries[record["country_code"]]}
        />
        <NumberInput source={"official_fee"} />
        <TextInput source={"official_fee_currency"} />
        <NumberInput source={"agent_fee"} />
        <TextInput source={"agent_fee_currency"} />
        <NumberInput source={"our_fee"} />
        <TextInput source={"our_fee_currency"} />
        <TextField source={"action_rule.action_code"} label={"Action code"} />
        <TextField source={"action_rule.action_type"} label={"Action type"}/>
        <TextField source={"action_rule.action_name"} label={"Action name"}/>
        <FunctionField
            label="Application type"
            render={(record: any) => `${record.action_rule?.application_type_inverse ? "NOT " : ""} ${record.action_rule.application_type}`}
        />
    </RowForm>
);
