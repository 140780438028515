import {BooleanInput, DateInput, Edit, FormDataConsumer, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import {countriesOptions} from "../../utils/countries";
import {
    applicationType2Options,
    applicationTypeOptions,
    CASE_FIELDS, CASE_TYPE_DESIGN, CASE_TYPE_INFRINGEMENT, CASE_TYPE_OPPOSITION,
    CASE_TYPE_PATENT, CASE_TYPE_TRADEMARK,
    caseTypeOptions
} from "../CaseList";
import { ApplicantNameInput } from "./ApplicantNameInput";
import {caseStatusList, isPCTOrEP, parseDesignatedStates, validateDesignatedStates} from "./utils";
import {CaseTitle} from "./CaseTitle";
import {useTenantId} from "../../data/useTenantId";
import {
    DesignCaseInputFields,
    OppositionInputFields,
    PatentCaseInputFields,
    TrademarkCaseInputFields
} from "./CaseInputFields";

export const CaseEdit = (props: any) => {
    return (
        <Edit title={<CaseTitle/>} mutationMode="optimistic" {...props} >
            <SimpleForm maxWidth={"sm"}>
                <TextInput disabled source="id"/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_PATENT) return <PatentCaseInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_TRADEMARK) return <TrademarkCaseInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_DESIGN) return <DesignCaseInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_INFRINGEMENT) return <OppositionInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_OPPOSITION) return <OppositionInputFields />
                        return null;
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
};