import {AutocompleteInput, ReferenceInput } from "react-admin";



export const ClientInput = (props: any) => {
    return (
        <ReferenceInput reference="tenants" source="tenant_id" label="Client" {...props}>
            <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />
        </ReferenceInput>
    );
}