import * as React from 'react';
import {Link, useRecordContext } from 'react-admin';

export const CaseRefField = (props: any) => {
    const record = useRecordContext(props);
    const idSource = props.idSource || "id";
    const source = props.source || "case_ref";
    const casesShowPage = `/cases/${record[idSource]}/show`;
    return record ? <Link to={casesShowPage}>{record[source]}</Link> : null;
};

CaseRefField.defaultProps = { label: 'Case ref' };
