import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {CheckboxGroupInput, Datagrid, DateField, Form, Link, List, ListBase,
    RadioButtonGroupInput, TextField, Title, useDataProvider, WithListContext } from 'react-admin';
import {Page} from "./utils/Page";
import { Box, Stack } from "@mui/system";
import {Button, CardActionArea, CardActions, CardHeader, Checkbox, Chip, Divider, FormControlLabel, Skeleton, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import {alternateBackground, CASE_FIELDS} from "./cases/CaseList";
import {CaseRefField} from "./cases/crud/CaseRefField";
import {FlagField} from "./utils/FlagField";
import {NextSubAction} from "./cases/crud/CaseActionsList";
import {dateFormat, SelectCountriesButton} from "./cases/actions/Actions";
import moment from "moment";
import {useTenantId} from "./data/useTenantId";
import { useEffect } from "react";
import {caseSubActionSort} from "./cases/actions/SubActionsList";
import {useIsAdmin} from "./auth/utils";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from '@mui/icons-material/GetApp';
import ChartIcon from '@mui/icons-material/BarChart';
import HelpIcon from '@mui/icons-material/HelpOutline';

const annuitiesRequest = (body: object) => fetch(
    `${process.env.REACT_APP_FUNCTIONS_URL}/annuitiesPrices` as string,
    {
        method: "POST",
        body: JSON.stringify(body)
    });

export const Budget = (props: any) => {
    const tenantId = useTenantId();
    const isAdmin = useIsAdmin();
    const auth0Data = useAuth0();
    const userName = auth0Data?.user?.name;
    const [annutiesIsLoading, setAnnuitiesIsLoading] = React.useState(false);
    const [annuitiesPrices, setAnnuitiesPrices] = React.useState<any[] | null>(null);
    const dataProvider = useDataProvider();
    console.log(auth0Data?.user);

    useEffect(() => {
        setAnnuitiesIsLoading(true);
        dataProvider.getList("annuity_cost_forecasts", {
            pagination: {page: 1, perPage: 100},
            sort: { field: "from,created_at", order: "ASC,DESC" },
            filter: {
                tenant_id: tenantId,
                "name@_nilike": "Q",
                distinct_on: "from"
            }
        }).then(res => {
            setAnnuitiesIsLoading(false);
            setAnnuitiesPrices(res.data)
        }).catch(error => {
            console.error(error);
            setAnnuitiesIsLoading(false);
        });

        // annuitiesRequest({
        //     tenant_id: tenantId,
        //     from_date: "2024-07-01",
        //     to_date: "2024-09-30"
        // })
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log(data);
        //         setAnnuitiesIsLoading(false);
        //         setAnnuitiesPrices(data)
        //     })
        //     .catch(error => {
        //         console.error(error);
        //         setAnnuitiesIsLoading(false);
        //     });
    }, []);

    return (
        <Stack spacing={5} alignItems={"center"} marginTop={15}>
            <Typography variant="h2" component="h1" >
                Cost estimates
            </Typography>
            <Box width={"1000px"}>
                <Card>
                    {/*<CardActionArea component={Link} to={"/annuities?filter={\"due_date@_gte\":\"2024-07-01\",\"due_date@_lte\":\"2024-09-30\"}"}>*/}
                    <CardContent>
                        <Box>
                            <Form>
                                {/*<Typography variant="body1">Set you preferred cost period</Typography>*/}
                                <Box>
                                    <RadioButtonGroupInput
                                        source="period"
                                        label={"Cost period"}
                                        defaultValue={"annual"}
                                        // row={false}
                                        choices={[
                                            { id: 'annual', name: 'Annual' },
                                            { id: 'quarterly', name: 'Quarterly' },
                                            { id: 'monthly', name: 'Monthly' },
                                            { id: 'custom', name: 'Select custom' },
                                        ]}
                                    />
                                </Box>

                                <CheckboxGroupInput
                                    source="budgeting_items"
                                    label={"Select items to include in the cost estimates"}
                                    defaultValue={["Patent annuities"]}
                                    choices={["Patent annuities", "PCT National Filings", "EP Validations", "Trademark Renewals"].map((name: string) => ({ id: name, name }))}
                                />

                                <Box>
                                    <RadioButtonGroupInput
                                        source="dimesion"
                                        label={"Select dimension"}
                                        defaultValue={"No dimension"}
                                        choices={["No dimension", "Per family", "Per department", "Per product", "Per country"].map((name: string) => ({ id: name, name }))}
                                    />
                                </Box>

                                <Box marginBottom={4}>
                                    <Box><FormControlLabel control={<Checkbox defaultChecked />} label="Show estimated savings by using Breeze IP" /></Box>
                                    <Typography variant="caption" component="div" >
                                        The estimated cost savings are based on a comparison of paying them with Breeze versus paying them with a traditional patent agency.
                                        <Box>We estimate savings of 2 000 – 3 500 NOK per annuity payment. 2 000 is used in the calculations.</Box>
                                    </Typography>
                                </Box>

                                <LoadingButton
                                    loading={false}
                                    loadingPosition="start"
                                    type={"submit"}
                                    // disabled={true}
                                    // startIcon={<SearchIcon />}
                                    variant="contained"
                                    // style={{marginLeft: 20, marginTop: 14}}
                                >
                                    Calculate
                                </LoadingButton>
                            </Form>
                        </Box>
                        {/*<Typography variant="h5" component="h3" marginTop={2}>*/}
                        {/*    {annutiesIsLoading ? <Skeleton variant={"rounded"} animation={"wave"}/> : `NOK ${annuity?.total_cost_nok?.toLocaleString("nb-NO")}`}*/}
                        {/*</Typography>*/}
                        {/*<Typography variant="caption" component="div" >*/}
                        {/*    {annutiesIsLoading ? <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/> : `A total of ${annuity?.number_of_annuities} patent annuities are due to be paid in ${annuity.name}.`}*/}
                        {/*</Typography>*/}
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography variant="h6" component="h2" marginBottom={5} marginTop={5}>
                                Results
                            </Typography>
                            <Stack direction={"row"} spacing={2}>
                                <Button endIcon={<ChartIcon></ChartIcon>} >Show chart</Button>
                                <Button endIcon={<DownloadIcon></DownloadIcon>} >Excel Export</Button>
                            </Stack>
                        </Stack>
                        <Box>
                            {
                                annutiesIsLoading ?
                                    <div>
                                        <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                        <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                        <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                        <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                        <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                        <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                    </div> :
                                    <Stack divider={<Divider></Divider>} spacing={2}>
                                        {
                                            annuitiesPrices?.map((annuity: any) => (
                                                <CardActionArea component={Link} to={`/annuities?filter=${JSON.stringify({
                                                    "due_date@_gte": annuity.from,
                                                    "due_date@_lte": annuity.to,
                                                    ...(isAdmin ? {"tenant_id": tenantId} : {})
                                                })}`}>
                                                    <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
                                                        <Box flex={1}>
                                                            <Typography variant="body1" component="h2" >
                                                                {annuity.name}
                                                            </Typography>
                                                            <Typography variant="caption" component="div" >
                                                                {annuity.from} - {annuity.to}
                                                            </Typography>
                                                        </Box>
                                                        <Box textAlign={"right"}>
                                                            <Typography variant="body1" component="h3">
                                                                NOK {annuity?.total_cost_nok?.toLocaleString("nb-NO")}
                                                            </Typography>
                                                            <Typography variant="caption" component="div" >
                                                                {annuity.number_of_annuities ? `${annuity.number_of_annuities} annuit${annuity.number_of_annuities === 1 ? "y" : "ies"}` : "No annuities"}
                                                            </Typography>
                                                            <Chip size="small" label={`Save ${(annuity.number_of_annuities * 2000)?.toLocaleString("nb-NO")}`} />
                                                        </Box>
                                                    </Stack>
                                                </CardActionArea>
                                            ))
                                        }
                                    </Stack>
                            }

                        </Box>
                    </CardContent>
                    {/*</CardActionArea>*/}
                </Card>
            </Box>
        </Stack>
    );
};
