import { Box } from "@mui/system";
import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, FileInput, FileField, useDataProvider, FunctionField, DateField, useGetList } from 'react-admin';
import {RunTestCasesButton} from "./RunTestCasesButton";

export const DocketingTestCasesList = (props: any) => {
    const { data: action_rules, total, isLoading, error } = useGetList(
        'action_rules',
        {
            pagination: { page: 1, perPage: 100 },
            filter: { action_type: "OTHER" },
            sort: { field: 'action_code', order: 'DESC' }
        }
    );

    console.log("ACTION RULES", action_rules);

    const new_rules = (action_rules || []).map((rule: any) => ({
        action_code: rule.action_code,
        description: rule.description_for_ai,
        extract_attributes: rule.attributes_for_ai ? rule.attributes_for_ai.split(",") : [],
    })).filter((rule: any) => rule.description);

    console.log("NEW RULES", new_rules);

    return (
        <List {...props} sort={{ field: "id", order: "ASC" }}>
            <Box width={"800px"} marginBottom={10} marginTop={5} paddingX={2}>
                <strong>Rules configuration (sent to AI as instructions)</strong>
                {
                    new_rules.map((rule: any) => (
                        <Box key={rule.action_code} marginY={2}>
                            <div><strong>{rule.action_code}</strong></div>
                            <div><span>Description: </span>{rule.description}</div>
                            <div><span>Attributes: </span>{rule.extract_attributes.join(",")}</div>
                        </Box>
                    ))
                }
            </Box>
            <Datagrid bulkActionButtons={<RunTestCasesButton rules={new_rules}></RunTestCasesButton>}>
                <TextField source={"id"} />
                <TextField source={"title"} />
                <FunctionField
                    label={"text"}
                    render={(record: any) => <span title={record?.text}>{record?.text?.length > 1000 ? record?.text?.substr(0, 1000) + "..." : record?.text}</span> }
                ></FunctionField>
                {/*<TextField source={"text"} />*/}
                <FunctionField
                    label={"expected_result"}
                    render={(record: any) => {
                        let parsed;
                        try {
                            parsed = JSON.parse(record?.expected_result || '""');
                        } catch (e) {
                        }

                        return parsed ? <pre>{JSON.stringify(parsed, null, 2)}</pre> : record?.expected_result ? record?.expected_result : null
                    } }
                />

                <FunctionField
                    label={"ai_result"}
                    render={(record: any) => {
                        let parsed;
                        try {
                            parsed = JSON.parse(record?.ai_result);
                        } catch (e) {
                        }
                        const { explanation, ...rest } = parsed ? parsed : { explanation: "" };
                        const obj = rest || {};
                        console.log(record?.ai_result, obj);
                        return parsed ? <pre>{JSON.stringify(record?.ai_result ? obj : {}, null, 2)}</pre> : record?.ai_result ? record?.ai_result : null
                    }}
                />
                <FunctionField
                    label={"explanation"}
                    render={(record: any) => {
                        const { explanation, ...rest } = record?.ai_result ? JSON.parse(record?.ai_result) : { explanation: "" };
                        return explanation
                    }}
                />
                {/*<TextField source={"ai_query"} />*/}
                <DateField source={"last_test_run"} showTime />
                <EditButton />
            </Datagrid>
        </List>
    );
};

const DocketingTestCaseForm = (props: any) => {
    return (
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source={"title"} />
            <TextInput source={"text"} multiline fullWidth />
            <TextInput source={"expected_result"} multiline fullWidth />
        </SimpleForm>
    );
}

export const DocketingTestCaseEdit = (props: any) => (
    <Edit {...props}>
        <DocketingTestCaseForm />
    </Edit>
);

export const DocketingTestCaseCreate = (props: any) => (
    <Create {...props}>
        <DocketingTestCaseForm />
    </Create>
);
