import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import React from "react";
import {ArrayField, ArrayInput,
    Button, Datagrid, Edit, EditButton, List, SelectInput, SimpleForm, TextField, TextInput, useDataProvider } from "react-admin";
import {countries} from "../utils/countries";
import {CountryInput} from "../utils/CountryInput";
import {CountryField} from "../utils/CountryField";


const rulesToCreate = Object.keys(countries).map((country_code: string) => ({
    type: "CLOSE",
    country_code: country_code,
    legal_event_codes: [],
}));
const filters = [
    <SelectInput source={"type"} label={"Type"} alwaysOn choices={[{ id: "GRANT", name: "Grant" }, { id: "CLOSE", name: "Close" }]}/>,
    <CountryInput source={"country_code"} label={"Country"} alwaysOn />,
];
export const LegalEventCountryCodesList = (props: any) => {
    // const dataProvider = useDataProvider();
    // const onClick = (e: any) => {
    //     Promise.all(rulesToCreate.map((rule) => {
    //         return dataProvider.create("legal_event_country_codes", { data: rule });
    //     }))
    //         .then(console.log)
    //         .catch(console.error);
    //
    // }
    return (
        <List {...props} sort={{ field: "country_code", order: "ASC" }} filters={filters}>
            <EditableDatagrid bulkActionButtons={false} mutationMode="optimistic" editForm={<Form></Form>} createForm={<Form></Form>} rowClick={"edit"}>
                <TextField source={"type"} />
                <TextField source={"country_code"} textTransform={"uppercase"} />
                <CountryField  />
                <TextField source={"legal_event_codes"} />
            </EditableDatagrid>
        </List>
    );
};

const Form = (props: any) => {
    return (
        <RowForm>
            {/*<TextInput disabled source="id" />*/}
            <TextInput source="type" disabled />
            <TextInput source="country_code" disabled />
            <CountryField/>
            <TextInput source="legal_event_codes" parse={(v) => v ? v.split(",") : []} format={(stateValue) => (stateValue || []).join(",")} fullWidth label={"EPO event codes (comma separated)"}/>
        </RowForm>
    );
};

