import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import {Datagrid, DateField, Form, FormDataConsumer, FunctionField, List, ReferenceManyField, TextField, useListContext } from "react-admin";
import {annuitiesManagementRowSx, AnnuityConclusionInput} from "./AnnuitiesManagementPeriod";
import {Flag} from "../../utils/Flag";
import {countries} from "../../utils/countries";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

export const AnnuityManagementDialogButton = () => {
    const { selectedIds } = useListContext();
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    console.log(selectedIds);
    return (
        <Box >
            <Button
                onClick={() => setOpen(true)}
            >
                Manage
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
                <DialogTitle>{`Submit instructions`}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Typography variant="h5">
                        Submit instructions
                    </Typography>
                    <Box>
                        <Box component="p">
                            <Typography variant="body1">
                                Please review the order and submit your payment instructions.
                            </Typography>
                        </Box>
                        <Box component="p">
                            <Typography variant="body1">
                                <strong>What's next?</strong>
                                <br/>
                                Breeze IP will send you an order confirmation that confirms the payment instructions. You will also receive an invoice which has to be paid within 10 days. The annuity payments will be processed once the invoice is paid.
                            </Typography>
                        </Box>

                    </Box>
                    <Form record={{}}>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => (
                                <List resource={"annuities"} filter={{ id: selectedIds }}>
                                    <Datagrid bulkActionButtons={false} rowSx={annuitiesManagementRowSx(formData)} hover={false}>
                                        <TextField source="case_action.case.case_ref" label={"Case ref."}/>
                                        {/*<TextField source="case_action.case.country_code" textTransform={"uppercase"} label={"Country code"} />*/}
                                        <FunctionField
                                            label="Country"
                                            sx={{ textWrap: "nowrap" }}
                                            render={(record: any) => record?.case_action?.case?.country_code ?
                                                <Box><Flag countryCode={record.case_action.case.country_code === "up" ? "eu" : record.case_action.case.country_code}></Flag>{"  " + countries[record.case_action.case.country_code]}</Box> : null
                                            }
                                        />
                                        <TextField source="case_action.case.application_number" label={"Application number"} />
                                        <TextField source="case_action.case.catchword" label={"Catchword"} />
                                        <TextField source="case_action.case.registration_number" label={"Registration number"}/>
                                        <TextField source="case_action.action_rule.action_name" label={"Annuity"} />
                                        <DateField source="case_action.due_date" label={"Due date"} />
                                        <FunctionField
                                            label="Price"
                                            sx={{ textWrap: "nowrap" }}
                                            render={(record: any) => `${record.usd_price} USD`}
                                        />
                                        <AnnuityConclusionInput></AnnuityConclusionInput>
                                    </Datagrid>
                                </List>
                            )}
                        </FormDataConsumer>
                    </Form>

                    <Box>
                        <Box component="p">
                            <Typography variant="body1">
                                VAT Notice: The prices are given ex. VAT. Our service fees are subject to 25% VAT for Norwegian companies, which will be itemized separately on your invoice.
                            </Typography>
                        </Box>
                        <Box component="p">
                            <Typography variant="body1">
                                Terms: The order is subject to the previously signed terms.
                            </Typography>
                        </Box>
                    </Box>

                    <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ my: 2}}>
                        {/*<CancelButton/>*/}
                        {/*<ConfirmPaymentInstructionsButton/>*/}
                    </Stack>
                </DialogContent>
            </Dialog>


        </Box>
    );
}
