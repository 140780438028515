import * as React from "react";
import { SelectInput, Edit, SimpleForm, TextInput, BooleanInput, SimpleFormIterator, ReferenceInput, useSimpleFormIteratorItem, ArrayInput } from 'react-admin';
import {applicationTypeOptions, caseTypeOptions} from "../cases/CaseList";
import { ReferenceManyInput } from '@react-admin/ra-relationships';
import {Box, Stack } from "@mui/system";
import { actionTypeOptions } from "../cases/actions/utils";

const applicationTypeOptionsIncludingAll = [{ id: 'All', name: 'All'}, ...applicationTypeOptions];
const responsibleRoleOptions = ["PATENT_ATTORNEY", "PARALEGAL", "ANNUITIES", "SYSTEM"].map((type) => ({ id: type, name: type}));
const startDateLogicOptions = [
    "DATE_OF_TODAY",
    "SAME_AS_DUE_DATE",
    "3M_BEFORE_DUE_DATE",
    "4M_BEFORE_DUE_DATE",
    "7D_AFTER_START_DATE",
    "14D_AFTER_START_DATE",
].map((type) => ({ id: type, name: type}))
const dueDateLogicOptions = [
    "SAME_AS_DUE_DATE",
    "3M_BEFORE_DUE_DATE",
    "4M_BEFORE_DUE_DATE",
    "7D_AFTER_START_DATE",
    "14D_AFTER_START_DATE",
].map((type) => ({ id: type, name: type}))
const extractFieldOptions = ["letter_date"].map((type) => ({ id: type, name: type}))

export const ActionRuleEdit = () => {
    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm sanitizeEmptyValues>
                <Stack direction={"row"} spacing={2} flexWrap="wrap">
                    <TextInput source="action_code" />
                    <TextInput source="status" multiline/>
                    <TextInput source="action_name" multiline/>
                    <SelectInput source={"responsible_role"} choices={responsibleRoleOptions}/>
                    <SelectInput source="extract_field" choices={extractFieldOptions} />
                    <SelectInput source={"action_type"} choices={actionTypeOptions}/>
                    <SelectInput source={"case_type"} choices={caseTypeOptions} defaultValue={caseTypeOptions[0]}/>
                    <SelectInput source={"application_type"} choices={applicationTypeOptionsIncludingAll}/>
                    <BooleanInput source={"application_type_inverse"} label={"Inverse application type filter (i.e NOT *application type*)"}/>
                    <BooleanInput source={"active"} />
                </Stack>
                <Box width={"100%"} maxWidth={"400px"}>
                    <TextInput source="description_for_ai" label={"Description for AI"} multiline fullWidth />
                    <TextInput source="attributes_for_ai" label={"Attributes for AI (comma separated)"} multiline fullWidth />
                </Box>
                <ReferenceManyInput reference="action_sub_rules" target="action_rule_id" sort={{ field: "code", order: "ASC" }}>
                    <SimpleFormIterator inline>
                        <TextInput source="code" />
                        <TextInput source="status" multiline/>
                        <TextInput source="action_name" multiline/>
                        <SelectInput source={"responsible_role"} choices={responsibleRoleOptions}/>
                        <ReferenceInput reference="email_templates" source="email_template_id" label="Email template">
                            <SelectInput source={"name"} label={"Email template"} optionText={"name"}/>
                        </ReferenceInput>
                        <SelectInput source="internal_start_date_logic" choices={startDateLogicOptions} />
                        <SelectInput source="internal_due_date_logic" choices={dueDateLogicOptions} />
                        <BooleanInput source="inactivates_action_rule" />
                    </SimpleFormIterator>
                </ReferenceManyInput>
            </SimpleForm>
        </Edit>
    );
};
