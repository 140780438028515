import { Box } from "@mui/system";
import React, { FunctionComponent } from "react";
import {FunctionField, useRecordContext } from "react-admin";
import {countries, euFlagCountries} from "./countries";
import { Flag } from "./Flag";

export const FlagField = (props: any) => {
    const { source, ...rest } = props;
    return (
        <FunctionField
            label="Country"
            source={source}
            {...rest}
            render={(record: any) => (
                <Box><Flag countryCode={euFlagCountries.includes(record[source]) ? "eu" : record[source]}></Flag>{"  " + countries[record[source]]}</Box>
            )}
        />
    );
};