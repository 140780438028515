import React, { FC } from "react";
import { FunctionField } from "react-admin";
import {countries} from "./countries";

export const CountryField: FC<{label?: string, source?: string}> = ({ label, source}) => (
    <FunctionField
        label={label || "Country"}
        render={(record: any) => countries[record[source || "country_code"]]}
    />
);
