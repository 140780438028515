import React, {Fragment, useEffect, useState} from "react";
import {countries} from "../../utils/countries";
import {CaseRoleList} from "./CaseRoleList";
import {AnnuityCaseActionsList, CaseActionsList} from "./CaseActionsList";
import {alternateBackgroundColor, CASE_FIELDS, CASE_TYPE_PATENT, caseActionsFilter, compactTable} from "../CaseList";
import {
    BooleanInput,
    Button,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    Filter,
    FunctionField,
    Link,
    List,
    ListButton,
    ReferenceField,
    ReferenceManyField,
    ReferenceOneField,
    SaveButton,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    useRecordContext,
    WithRecord
} from "react-admin";
import {CaseTitle} from "./CaseTitle";
import {CopyCaseButton} from "../CopyCaseButton";
import PlusIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/GetApp';
import {useIsAdmin} from "../../auth/utils";
import {Box, Chip, Grid, Stack, Typography} from "@mui/material";
import {CreateActionsButton} from "../actions/CreateActionsButton";
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import {CitationSpecificFields, DOCUMENT_FIELDS, documentTypes} from "../../documents/documents";
import {QuickFilter} from "../../utils/QuickFilter";
import {ANNUITY} from "../actions/utils";
import {capitalizeFirstLetter, dateFormat} from "../actions/Actions";
import {TenantContext} from "../../react-admin-overrides/AppLayout";
import {CaseRefField} from "./CaseRefField";
import {FlagField} from "../../utils/FlagField";
import {ApplicationNumberField} from "./AppplicationNumberField";
import {CitationsField} from "./CitationsField";
import moment from "moment";
import {CASE_STATUS_CLOSED, CASE_STATUS_GRANTED} from "./utils";
import {SyncWithEPOButton} from "./CaseSyncWithEPO";


export const CaseShow = (props: any) => {
    const isAdmin = useIsAdmin();
    const record = useRecordContext();
    const {tenant} = React.useContext(TenantContext) || {};
    const documents = tenant?.feature_documents || isAdmin;
    return (
        <Show {...props} title={<CaseTitle/>} actions={<CaseShowActions/>}>
            <h1><CaseTitle/></h1>
            <TabbedShowLayout>
                <Tab label="overview">
                    <Grid container spacing={2} sx={{maxWidth: "800px"}}>
                        <Grid item xs={6}>
                            <WithRecord render={(record: any) =>
                                <SimpleShowLayout>
                                    <TextField source={CASE_FIELDS.CASE_TYPE}/>
                                    <TextField source={CASE_FIELDS.APPLICATION_NUMBER}/>
                                    {/*<TextField source={CASE_FIELDS.COUNTRY_CODE} />*/}
                                    <FunctionField
                                        label="Country"
                                        render={(record: any) => countries[record[CASE_FIELDS.COUNTRY_CODE]]}
                                    />
                                    {record[CASE_FIELDS.COUNTRY_CODE] === "pc" &&
                                        <TextField source={CASE_FIELDS.DEMAND_FOR_CHAPTER_2}
                                                   label={"Demand for chapter II"}/>}
                                    <TextField source={CASE_FIELDS.CATCHWORD}/>
                                    <TextField source={CASE_FIELDS.INVENTION_TITLE}/>
                                    <TextField source={CASE_FIELDS.APPLICATION_TYPE}/>
                                    <TextField source={CASE_FIELDS.APPLICATION_TYPE_2}/>
                                    <TextField source={CASE_FIELDS.BASED_ON_PCT}/>
                                    <DateField source={CASE_FIELDS.FILING_DATE}/>
                                    <DateField source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}/>
                                    <DateField source={CASE_FIELDS.NATIONAL_FILING_DATE}/>
                                    <TextField source={"entity_size"} label={"Override entity size"}/>
                                </SimpleShowLayout>
                            }/>
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleShowLayout>
                                <DateField source={CASE_FIELDS.REGISTRATION_DATE}/>
                                <TextField source={CASE_FIELDS.REGISTRATION_NUMBER}/>
                                <TextField source={CASE_FIELDS.STATUS}/>
                                <DateField source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE}/>
                                <TextField source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER}/>
                                <FunctionField
                                    label="Priority country"
                                    render={(record: any) => countries[record[CASE_FIELDS.PRIORITY_COUNTRY_CODE]]}
                                />
                                <TextField source={CASE_FIELDS.DESIGNATED_STATES}/>
                                <DateField source={"expiration_date"}/>
                                <DateField source={CASE_FIELDS.CREATED_AT}/>
                                <DateField source={CASE_FIELDS.UPDATED_AT}/>
                                {
                                    isAdmin &&
                                    <DateField source={"closed_at"}/>
                                }
                                {
                                    isAdmin &&
                                    <TextField source={"closed_event_description"}/>
                                }
                                {
                                    isAdmin &&
                                    <TextField source={"word_count"}/>
                                }
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                    {/*<TextField source="id" />*/}
                    {/*<CaseTextField></CaseTextField>*/}


                </Tab>
                {
                    // isAdmin &&
                    <Tab label="roles" path="roles">
                        <ReferenceManyField label="Roles" reference="case_roles" target="case_id">
                            <CaseRoleList {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {
                    isAdmin &&
                    <Tab label="admin" path="admin">
                        <Edit hasShow={false}>
                            <SimpleForm toolbar={
                                <Toolbar>
                                    <SaveButton label="Save" />
                                </Toolbar>
                            }>
                                <Stack spacing={5}>
                                    <BooleanInput source="calc_maintenance_actions_and_fees" helperText={"Switching this off will turn off future action calculations for Annuities and Renewals. It will not delete already existing actions"} />
                                    <TextInput source="import_comments" helperText={"This text will be included in the Excel report we send to clients after we have concluded importing their data to the system"} />
                                </Stack>
                            </SimpleForm>
                        </Edit>
                    </Tab>
                }
                {
                    documents &&
                    <Tab label="documents" path="documents">
                        <WithRecord render={(record: any) => (
                            <List
                                resource={"case_documents"}
                                filter={{case_id: record?.id}}
                                filters={<DocumentsFilter/>}
                                exporter={false}
                                hasCreate={false}
                                pagination={false}
                                perPage={50}
                                component={(props) => <div>{props?.children}</div>}
                            >
                                <Datagrid>
                                    {/*{isAdmin && <TextField source="id"/>}*/}
                                    {/*<TextField source={"type"}/>*/}
                                    <FunctionField label={"Type"}
                                                   render={(record: any) => record[DOCUMENT_FIELDS.TYPE] ?
                                                       capitalizeFirstLetter(record[DOCUMENT_FIELDS.TYPE]?.replaceAll("_", " ").toLowerCase())
                                                       : null
                                                   }></FunctionField>

                                    <TextField source={"title"}/>
                                    <TextField source={"description"}/>
                                    <DateField source={"created_at"} showTime label={"Uploaded"}/>
                                    {isAdmin && <TextField source={"bucket_file_name"}/>}
                                    <TextField source={"original_file_name"} label={"File name"}/>
                                    <FunctionField label={"Citation"}
                                                   render={(record: any) => record[DOCUMENT_FIELDS.TYPE] === "CITATION" ?
                                                       record.citation_type === "PATENT" ?
                                                           `${record.citation_application_number || record.citation_registration_number}` :
                                                           `${record.citation_authors}`
                                                       : null
                                                   }></FunctionField>
                                    <DownloadDocumentButton/>
                                    <EditDocumentButton/>
                                </Datagrid>
                            </List>
                        )}/>
                    </Tab>
                }
                <Tab label="actions" path="actions">
                    <div>
                        <h3>Actions</h3>
                        <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                            sort={{field: "due_date", order: "ASC"}}
                                            filter={caseActionsFilter}>
                            <CaseActionsList/>
                        </ReferenceManyField>
                        <WithRecord render={(record: any) => (
                            record?.case_type === CASE_TYPE_PATENT ?
                            <Box>
                                <h3>Annuities</h3>
                                <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                                    sort={{field: "due_date", order: "ASC"}}
                                                    filter={{"action_rule#action_type": ANNUITY}}>
                                    <AnnuityCaseActionsList/>
                                </ReferenceManyField>
                            </Box> : null
                        )} />
                    </div>
                    {/*<ReferenceManyField reference="case_actions" target="case_id"*/}
                    {/*                    sort={{field: "due_date", order: "ASC"}}*/}
                    {/*                    filter={caseActionsFilter}*/}
                    {/*>*/}
                    {/*    <CaseActionsList/>*/}
                    {/*</ReferenceManyField>*/}
                </Tab>
                {
                    isAdmin &&
                    <Tab label="family" path="family">
                        <WithRecord render={(record: any) => {
                            if (record?.case_type === CASE_TYPE_PATENT && record?.case_ref) {
                                const familyPart = record?.case_ref?.match(/P\d+/)[0];
                                return (
                                    <div>
                                        <h3>Family / case_ref starting with {familyPart}</h3>
                                        <List
                                            resource={"cases"}
                                            storeKey={"cases/family-cases"}
                                            sort={{field: "case_ref", order: "ASC"}}
                                            filter={{"case_ref": familyPart}}
                                            perPage={100}
                                        >
                                            <Datagrid bulkActionButtons={false} rowSx={alternateBackgroundColor}
                                                      sx={compactTable}
                                            >
                                                <CaseRefField source={CASE_FIELDS.CASE_REF}></CaseRefField>
                                                <TextField source={CASE_FIELDS.APPLICANT}/>
                                                <FlagField label={"Country"} source={"country_code"}
                                                           sx={{textWrap: "nowrap"}}></FlagField>
                                                <ApplicationNumberField source={CASE_FIELDS.APPLICATION_NUMBER}/>
                                                <TextField source={CASE_FIELDS.CATCHWORD}/>
                                                <FunctionField
                                                    label={"Title"}
                                                    source={CASE_FIELDS.INVENTION_TITLE}
                                                    render={(record: any) => <span
                                                        title={record?.invention_title}>{record?.invention_title?.length > 50 ? record?.invention_title?.substr(0, 50) + "..." : record?.invention_title}</span>}
                                                ></FunctionField>
                                                <TextField source={CASE_FIELDS.APPLICATION_TYPE}/>
                                                <FunctionField
                                                    label="Priority claim"
                                                    render={(record: any) => record[CASE_FIELDS.PRIORITY_APPLICATION_NUMBER] ? "With priority" : "Without priority"}
                                                />
                                                <TextField source={CASE_FIELDS.BASED_ON_PCT}/>
                                                <DateField source={CASE_FIELDS.FILING_DATE}/>
                                                <DateField source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}/>
                                                <DateField source={CASE_FIELDS.NATIONAL_FILING_DATE}/>
                                                <DateField source={CASE_FIELDS.REGISTRATION_DATE}/>
                                                <TextField source={CASE_FIELDS.REGISTRATION_NUMBER}/>
                                                <TextField source={CASE_FIELDS.STATUS}/>

                                                <DateField source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE}/>
                                                <TextField source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER}/>
                                                <FunctionField
                                                    label="Priority Country"
                                                    render={(record: any) => record[CASE_FIELDS.PRIORITY_COUNTRY_CODE]?.toUpperCase()}
                                                />

                                                <TextField source={CASE_FIELDS.PRODUCT}/>
                                                <TextField source={CASE_FIELDS.DEPARTMENT}/>
                                                <CitationsField label={"Citations"}/>
                                                <FunctionField
                                                    label={"Priority claims"}
                                                    render={(record: any) => <span>{(record?.priority_claims || []).map(
                                                        (c: any) => <Chip
                                                            label={`${c.priority_country_code} ${c.priority_application_number} ${c.priority_filing_date}`}
                                                            sx={{marginBottom: 1}}></Chip>)
                                                    }</span>}
                                                ></FunctionField>
                                                <ReferenceField reference="names" source={"primary_agent_id"}
                                                                label={"Agent"}>
                                                    <TextField source={"name"}/>
                                                </ReferenceField>
                                                <ReferenceField reference="names" source={"local_agent_id"}
                                                                label={"Local Agent"}>
                                                    <TextField source={"name"}/>
                                                </ReferenceField>
                                                <ReferenceOneField
                                                    label="Next annuity"
                                                    reference="case_actions"
                                                    target={"case_id"}
                                                    sort={{field: "due_date", order: "ASC"}}
                                                    filter={{
                                                        "completed_at@_is_null": true,
                                                        "action_rule#action_type": ANNUITY,
                                                        "due_date@_gte": moment().format(dateFormat)
                                                    }}
                                                >
                                                    <DateField source="due_date"/>
                                                </ReferenceOneField>
                                            </Datagrid>
                                        </List>
                                    </div>
                                )
                            }
                            return null;
                        }}></WithRecord>
                        {/*<ReferenceManyField reference="case_actions" target="case_id"*/}
                        {/*                    sort={{field: "due_date", order: "ASC"}}*/}
                        {/*                    filter={caseActionsFilter}*/}
                        {/*>*/}
                        {/*    <CaseActionsList/>*/}
                        {/*</ReferenceManyField>*/}
                    </Tab>
                }

                {

                }
            </TabbedShowLayout>
        </Show>
    )
};

const DocumentsFilter = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <SelectInput source={"type"} choices={documentTypes} emptyText={"All"}/>
            <QuickFilter source={"type@_neq"} label={"Hide citations"} defaultValue={"CITATION"}></QuickFilter>
        </Filter>
    );
};


export const DownloadDocumentButton = () => {
    const record = useRecordContext();
    const [startDownload, setStartDownload] = useState<boolean>(false);
    const [downloadLink, setDownloadLink] = useState<string>();
    console.log(record);

    useEffect(() => {
        if (startDownload) {
            console.log("download", record);
            // fetch(`https://6pk5u8qwz3.execute-api.eu-north-1.amazonaws.com/getDownloadUrl/${record.bucket_file_name}/${record.original_file_name}`)
            fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getDownloadUrl?bucketFilename=${record.bucket_file_name}&originalFilename=${record.original_file_name}`)
                .then(r => r.json())
                .then((body) => setDownloadLink(body.url));
            setStartDownload(false);
        }
    }, [startDownload])

    useEffect(() => {
        if (downloadLink) {
            console.log("link", downloadLink);
            const link = document.createElement('a');
            link.href = `${downloadLink}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }, [downloadLink])

    if (!record?.bucket_file_name) return null;

    return (
        <Button
            label="Download"
            onClick={() => setStartDownload(true)}
        >
            <DownloadIcon/>
        </Button>
    )
};


const CaseShowActions = () => {
    const isAdmin = useIsAdmin();
    return (
        <TopToolbar>
            <ListButton label={"Cases"}/>
            {/*{isAdmin && <EditButton/>}*/}
            <EditButton/>
            {/*{isAdmin && <AddDocumentButton/>}*/}
            <AddDocumentButton/>
            {isAdmin && <CopyCaseButton label={"Copy to other country"}/>}
            {isAdmin && <CreateActionsButton/>}
            {/*<CopyCaseButton label={"Copy to other country"}/>*/}
            {isAdmin && <RecreateActionsButton/>}
            {isAdmin && <SyncWithEPOButton/>}
        </TopToolbar>
    );
};

const AddDocumentButton = () => {
    const caseRecord = useRecordContext();
    const isAdmin = useIsAdmin();
    const {tenant} = React.useContext(TenantContext) || {};
    const documents = tenant?.feature_documents || isAdmin;
    return documents ? (
        <Button
            component={Link}
            to={{
                pathname: "/case_documents/create",
                // Here we specify the initial record for the create view
                state: {record: {case_id: caseRecord?.id}},
                search: caseRecord ? `case_id=${caseRecord.id}` : ""
            }}
            label="Add a document"
        >
            <PlusIcon/>
        </Button>
    ) : null;
};

const EditDocumentButton = () => {
    const caseDocument = useRecordContext();
    return (
        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit document '${caseDocument.title}'`}>
            <Box sx={{minWidth: "600px"}}>
                <SimpleForm maxWidth={"sm"}>
                    <SelectInput source={DOCUMENT_FIELDS.TYPE} isRequired choices={documentTypes}/>
                    <TextInput source={DOCUMENT_FIELDS.TITLE} resettable fullWidth/>
                    <TextInput source={DOCUMENT_FIELDS.DESCRIPTION} multiline resettable fullWidth/>
                    <CitationSpecificFields/>
                </SimpleForm>
            </Box>
        </EditInDialogButton>
    );
};


export const RecreateActionsButton = () => {
    const caseRecord = useRecordContext();
    if (!caseRecord) return null;

    return (
        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Recreate actions`} label={`Recreate actions`}
                            icon={<></>}>
            <RecreateActionsForm/>
        </EditInDialogButton>
    );
}

export const RecreateActionsForm = () => {
    const caseRecord = useRecordContext();
    return (
        <Box sx={{minWidth: "600px"}}>
            <SimpleForm
                record={{...caseRecord, recreate_case_actions_trigger: moment()}}
                maxWidth={"sm"}
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Generate actions"} alwaysEnable/>
                    </Toolbar>
                }
            >
                {/*<BooleanInput source={"recreate_case_actions_trigger"} />*/}
                <Box>
                    <Typography>
                        Recreate all actions for this case. This will delete all existing, automatically created,
                        actions and recreate them based on the current case data and country rules.
                    </Typography>
                </Box>
                <br/>
                <Box>
                    <Typography>
                        The generation happens in the background and should be done in a few seconds after you click
                        "Generate actions". You can update the page manually by clicking the refresh button in the
                        browser or in the menu. The data (and new actions) will also be updated if you return to the
                        browser window after having used another application.
                    </Typography>
                </Box>
            </SimpleForm>
        </Box>
    );
}

export const CaseStatusField = (props: any) => {
    const caseRecord = useRecordContext();
    if (!caseRecord) return null;

    return caseRecord.status === CASE_STATUS_GRANTED ?
        <Chip label={ `${caseRecord.status} ${moment(caseRecord.registration_date).format(dateFormat)}` }></Chip> :
        caseRecord.status === CASE_STATUS_CLOSED ?
            <Chip label={ `${caseRecord.status} ${moment(caseRecord.registration_date).format(dateFormat)}` }></Chip> :
            <Chip label={caseRecord.status}></Chip>
}

export const FilingDateField = (props: any) => {
    const caseRecord = useRecordContext();
    if (!caseRecord) return null;

    return caseRecord[CASE_FIELDS.INTERNATIONAL_FILING_DATE] ?
        <Chip label={ `Int. filing date ${moment(caseRecord[CASE_FIELDS.INTERNATIONAL_FILING_DATE]).format(dateFormat)}` }></Chip> :
            <Chip label={ `Filing date ${moment(caseRecord[CASE_FIELDS.FILING_DATE]).format(dateFormat)}` }></Chip>
}
