import React, {FunctionComponent, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {CloseDialogIconButton} from "./CloseDialogIconButton";

export const ButtonDialog: FunctionComponent<{ buttonTitle: string, dialogTitle: string, children: React.ReactElement }> = ({ buttonTitle, dialogTitle, children}) => {
    const [open, setOpen] = useState(false);

    const onClick = (e: any) => {
        e?.preventDefault();
        setOpen(true);
    }
    const close = (e: any) => {
        e?.preventDefault();
        setOpen(false)
    };

    return (
        <div>
            <Button
                onClick={onClick}
            >
                {buttonTitle}
            </Button>
            <Dialog open={open} onClose={close} fullWidth maxWidth="xl">
                <CloseDialogIconButton onClose={close}/>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                {/*<DialogActions>*/}
                    {/*<Button onClick={close}>Cancel</Button>*/}
                    {/*<Button onClick={startCopying}>Start copying</Button>*/}
                {/*</DialogActions>*/}
            </Dialog>
        </div>
    );
}
