

export const submitPaymentInstructionsMutation = `
mutation submitPaymentInstructionsMutation($pay_ids: [uuid!]!, $do_not_pay_ids: [uuid!]!, $wait_ids: [uuid!]!, $sent_at: timestamptz!, $sent_by: uuid!, $instructions: [instructions_received_insert_input!]!) {
  insert_instructions_received_event(objects: [{
    instructed_by_user: $sent_by,
    instructions_received: {
        data: $instructions
    }
  }]) {
    affected_rows
  }
  update_pay_instructions: update_case_actions_many(updates: {
    where: {
        id: { _in: $pay_ids }
    }, 
    _set: {
        annuity_payment_instructions_sent_at: $sent_at,
        annuity_payment_instructions_sent_by: $sent_by,
        annuity_payment_instructions_sent: true
    }
  }) {
    affected_rows
  }
  
  update_do_not_pay_instructions: update_case_actions_many(updates: {
    where: {
        id: { _in: $do_not_pay_ids }
    }, 
    _set: {
        annuity_paid_elsewhere_at: $sent_at,
        annuity_paid_elsewhere_by: $sent_by
    }
  }) {
    affected_rows
  }
  
  update_wait_instructions: update_case_actions_many(updates: {
    where: {
        id: { _in: $wait_ids }
    }, 
    _set: {
        instructions_wait_sent_at: $sent_at,
        instructions_wait_sent_by: $sent_by
    }
  }) {
    affected_rows
  }
}
`


