import React, {useState} from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Select, MenuItem, Checkbox, FormControlLabel, FormGroup, FormControl, InputLabel } from '@mui/material';
import { useRecordContext, useDataProvider } from 'react-admin';
import {
    FILING_DATE,
    INT_FILING_DATE_OR_FILING_DATE,
    INTERNATIONAL_FILING_DATE,
    REGISTRATION_DATE_AND_FILING_DATE,
    REGISTRATION_DATE_AND_IFD,
    REGISTRATION_DATE_AND_IFD_OR_FD,
    triggerDateOptions
} from "./triggerDate";
import {ActionRuleCreateType} from "../App";


export const CopyActionRuleButton = () => {
    const actionRule = useRecordContext();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const [formInput, setFormInput] = useState({
        copyCountryRules: false,
        triggerDate: "no-override",
    });

    const onClick = (e: any) => {
        e.preventDefault();
        setOpen(true);
    }
    const close = () => setOpen(false);
    const handleSelectChange = (event: any) => {
        event.preventDefault();
        const triggerDate = event?.target?.value
        setFormInput({ ...formInput, triggerDate });
    }

    // console.log(actionRule);

    const startCopying = (event: any) => {
        event.preventDefault();

        // Find rule data and pick fields to be included in the copy
        // Hard code the inverse option to true and change the action_code
        const {id, created_at, updated_at, ...rest} = actionRule;
        const actionRuleData = { ...rest, application_type: "All", action_name: rest.action_name.replaceAll(" (OLD)", "") };

        // Fetch the corresponding country rules

        dataProvider.getManyReference("action_country_rules", {
            target: 'action_rule_id',
            id,
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'created_at', order: 'DESC' },
            filter: { action_rule_id: id }
        }).then((body: any) => {
            console.log(actionRule);
            console.log(body);

            // Pick the fields to be included in the copy
            const newCountryRules = body.data.map((countryRule: any) => {
                const {id, created_at, updated_at, action_rule, action_rule_id, trigger_date, ...rest} = countryRule;
                const copiedTriggerDate =
                    [FILING_DATE, INTERNATIONAL_FILING_DATE].includes(trigger_date) ? INT_FILING_DATE_OR_FILING_DATE :
                        [REGISTRATION_DATE_AND_IFD, REGISTRATION_DATE_AND_FILING_DATE].includes(trigger_date) ? REGISTRATION_DATE_AND_IFD_OR_FD
                            : trigger_date;
                delete rest["action_rule.id"];
                console.log(rest);
                return { ...rest, trigger_date: copiedTriggerDate };
            });

            const data = { ...actionRuleData, action_country_rules: { data: newCountryRules}}

            console.log(data);

            // Insert action rule with corresponding new country rules to backend
            dataProvider.createManyActionRulesMutation([data])
                .then(console.log);
        });

        // dataProvider
        // // @ts-ignore
        //     .actionCountryRule(id)
        //     .then((body: any) => {
        //         console.log(actionRule);
        //         console.log(body);
        //         // Pick the fields to be included in the copy
        //         const newCountryRules = body.data.action_country_rules.map((countryRule: any) => {
        //             const {id, created_at, updated_at, ...rest} = countryRule;
        //             return { ...rest, trigger_date: INT_FILING_DATE_OR_FILING_DATE };
        //         });
        //
        //         const data = { ...actionRuleData, action_country_rules: { data: newCountryRules}}
        //
        //         console.log(data);
        //
        //         // Insert action rule with corresponding new country rules to backend
        //         // dataProvider.createManyActionRulesMutation([data])
        //         //     .then(console.log);
        //     })

    }

    return (
        <div>
            <Button
                onClick={onClick}
            >
                Copy
            </Button>
            <Dialog open={open} onClose={close}>
                <DialogTitle>{`Copy rule ${actionRule?.action_code} ${actionRule?.action_name}`}</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formInput.copyCountryRules}
                                    onChange={() => setFormInput({...formInput, copyCountryRules: !formInput.copyCountryRules})}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Copy country rules"
                        />
                        <br/>
                        {
                            formInput.copyCountryRules &&
                            <FormControl>
                                <InputLabel id="trigger-date-select-label">Override trigger date</InputLabel>
                                <Select
                                    labelId="trigger-date-select-label"
                                    onChange={handleSelectChange}
                                    value={formInput.triggerDate}
                                    label="Trigger date"
                                    sx={{ width: 300 }}>
                                    <MenuItem value="no-override">Don't override</MenuItem>
                                    {
                                        triggerDateOptions.map((triggerDateId) => <MenuItem value={triggerDateId}>{triggerDateId}</MenuItem>)
                                    }

                                </Select>
                            </FormControl>
                        }
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>Cancel</Button>
                    <Button onClick={startCopying}>Start copying</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
