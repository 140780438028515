import React, {FunctionComponent} from "react";
    import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const AppAuth0Provider: FunctionComponent = ({ children }) => {
    const navigate = useNavigate();
    const onRedirectCallback = (appState: any) => {
        console.log("Redirect callback", appState, navigate, window.location.pathname, window.location.href);
        navigate(appState?.returnTo || window.location.pathname);
    };
    console.log(window.location, process.env);
    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
            authorizationParams={{
                redirect_uri: window.location.origin,
                // scope: "openid profile email offline_access",
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                connection: "email",
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    );
};

export default AppAuth0Provider;