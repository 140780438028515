import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, SelectInput, ImageField, UrlField, FunctionField, FormDataConsumer, AutocompleteArrayInput, Filter} from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import {useTenantId} from "../data/useTenantId";
import {countries, countriesOptions} from "../utils/countries";
import {useIsAdmin} from "../auth/utils";
export const NameIcon = PersonIcon;

export type Name = {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
}

enum NAME_FIELDS {
    ID = "id",
    NAME = "name",
    EPODOC_NAME = "epodocName",
    TYPE = "type",
    SHORT_NAME = "short_name",
    EMAIL = "email",
    PHONE_NUMBER = "phone_number",
    FAX = "fax",
    OFFICE_ADDRESS = "office_address",
    MAIL_ADDRESS = "mail_address",
    LOGO_URL = "logo_url",
    WEBSITE = "website",
    TENANT_ID = "tenant_id",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
}
const excluded = ["all"];
const patentCountriesChoices = countriesOptions.filter((country) => !excluded.includes(country.id));

const NamesFilter = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <SelectInput source={NAME_FIELDS.TYPE} optionText={"name"} choices={nameTypeOptions} alwaysOn />
            <TextInput source={NAME_FIELDS.NAME} alwaysOn />
            <SelectInput
                source={"agent_handles_patent_country_codes@_contains"}
                label={"Handles patents in"}
                optionText={"name"}
                emptyText={"Clear filter"}
                choices={patentCountriesChoices}
                alwaysOn
                parse={(input: string) => input ? [input] : null}
                sx={{ width: 300 }}
            />
        </Filter>
    );
};

export const NameList = (props: any) => (
    <List {...props} sort={{ field: NAME_FIELDS.NAME, order: "ASC" }} filters={<NamesFilter/>} >
        <Datagrid>
            <TextField source={NAME_FIELDS.TYPE} />
            <TextField source={NAME_FIELDS.NAME} />
            <TextField source={NAME_FIELDS.SHORT_NAME} />
            <TextField source={NAME_FIELDS.EPODOC_NAME} />
            <TextField source={NAME_FIELDS.EMAIL} />
            <UrlField source={NAME_FIELDS.WEBSITE} target='_blank' rel="noreferrer"/>
            <TextField source={NAME_FIELDS.LOGO_URL}/>
            <ImageField source={NAME_FIELDS.LOGO_URL} />
            <FunctionField label={"Handles patents in"} render={(record: any) => (
                <div>
                    {(record.agent_handles_patent_country_codes || []).map((countryCode: any) => countries[countryCode] || countryCode).join(", ")}
                </div>
            )}></FunctionField>
            <EditButton />
        </Datagrid>
    </List>
);

const NameTitle = ({ record }: { record: Name }) => {
    return <span>Post {record ? `"${record.name}"` : ''}</span>;
};

const nameTypeOptions = ["Other", "Agent", "Inventor", "Search Agent"].map((type) => ({ id: type, name: type}))

export const NameEdit = (props: any) => {
    const tenantId = useTenantId();
    return (
        <Edit title={<NameTitle record={props.record} />} {...props}>
            <SimpleForm>
                {/*<TextInput hidden source={NAME_FIELDS.TENANT_ID} defaultValue={tenantId} style={{display: "none"}} />*/}
                <TextInput disabled source="id" />
                <SelectInput source={NAME_FIELDS.TYPE} choices={nameTypeOptions}/>
                <TextInput source={NAME_FIELDS.NAME} />
                <TextInput source={NAME_FIELDS.SHORT_NAME} />
                <TextInput source={NAME_FIELDS.EPODOC_NAME} />
                <TextInput source={NAME_FIELDS.EMAIL} />
                <TextInput source={NAME_FIELDS.PHONE_NUMBER} />
                <TextInput source={NAME_FIELDS.FAX} />
                <TextInput source={NAME_FIELDS.WEBSITE} />
                <TextInput source={NAME_FIELDS.LOGO_URL} />
                <TextInput source={NAME_FIELDS.OFFICE_ADDRESS} multiline sx={{ minWidth: "600px" }} />
                <TextInput source={NAME_FIELDS.MAIL_ADDRESS} multiline sx={{ minWidth: "600px" }} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData[NAME_FIELDS.TYPE] === "Agent" ?
                        <AutocompleteArrayInput label={"Handles patents in patentCountriesChoices"} source="agent_handles_patent_country_codes" choices={patentCountriesChoices} sx={{ minWidth: "600px" }} /> : null
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
};

export const NameCreate = (props: any) => {
    const tenantId = useTenantId();
    return (
        <Create title="Create a name" {...props}>
            <SimpleForm>
                {/*<TextInput hidden source={NAME_FIELDS.TENANT_ID} defaultValue={tenantId} style={{display: "none"}} />*/}
                <SelectInput source={NAME_FIELDS.TYPE} choices={nameTypeOptions}/>
                <TextInput source={NAME_FIELDS.NAME} />
                <TextInput source={NAME_FIELDS.SHORT_NAME} />
                <TextInput source={NAME_FIELDS.EPODOC_NAME} />
                <TextInput source={NAME_FIELDS.EMAIL} />
                <TextInput source={NAME_FIELDS.PHONE_NUMBER} />
                <TextInput source={NAME_FIELDS.FAX} />
                <TextInput source={NAME_FIELDS.WEBSITE} />
                <TextInput source={NAME_FIELDS.LOGO_URL} />
                <TextInput source={NAME_FIELDS.OFFICE_ADDRESS} multiline sx={{ minWidth: "600px" }} />
                <TextInput source={NAME_FIELDS.MAIL_ADDRESS} multiline sx={{ minWidth: "600px" }} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData[NAME_FIELDS.TYPE] === "Agent" ?
                        <AutocompleteArrayInput label={"Handles patents in patentCountriesChoices"} source="agent_handles_patent_country_codes" choices={patentCountriesChoices} sx={{ minWidth: "600px" }} /> : null
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};
