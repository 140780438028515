import React, { useEffect, useState } from "react";
import {CasePreview} from "./importCases";
import {useDataProvider} from "react-admin";

export const useImportCases = (onSuccess?: () => void) => {
    const dataProvider = useDataProvider();

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<any>(null)
    const [error, setError] = useState(null)

    const resetState = () => {
        setLoading(false);
        setError(null);
        setData(null);
    }

    const makeApiCall = (caseData: CasePreview[], tenant_id: string, next_case_ref_serial_number: number, user_id: string, search_text: string) => {
        setLoading(true);
        setError(null);
        setData(null);

        const data = caseData.map(c => {
            const {
                application_number,
                country_code,
                application_type,
                case_type,
                case_ref,
                filing_date,
                international_filing_date,
                abstract,
                invention_title,
                priority_country_code,
                priority_application_number,
                first_priority_filing_date,
                based_on_pct,
                applicant_name_id,
                applicant2_name_id,
                applicant3_name_id,
                designatedStates,
                registrationDate,
                registrationEPONumber,
                application_type_2,
                status,
                case_team_id,
                closed_at,
                closed_event_description,
                citations,
                priorityClaims,
            } = c;

            return {
                tenant_id,
                application_number,
                country_code: c.country_code?.toLowerCase(),
                application_type,
                case_type,
                case_ref,
                filing_date,
                international_filing_date,
                abstract,
                invention_title,
                based_on_pct,
                priority_country_code: c.priority_country_code?.toLowerCase(),
                priority_application_number,
                first_priority_filing_date,
                applicant_name_id,
                applicant2_name_id,
                applicant3_name_id,
                designated_states: designatedStates,
                registration_date: registrationDate,
                registration_number: registrationEPONumber,
                status,
                application_type_2,
                case_team_id,
                closed_at,
                closed_event_description,
                citations,
                priority_claims: priorityClaims
            }
        })

        const lastCaseRefSerialNumber = data[data.length - 1].case_ref
            ?.replace("P", "")
            ?.split(/[A-Za-z]/g)[0];

        dataProvider.createManyCasesMutation(data, tenant_id, Number(lastCaseRefSerialNumber) + 1, user_id, search_text)
            .then((data: any) => {
                console.log(data);
                setLoading(false);
                setData(data);

                onSuccess && onSuccess();
            })
            .catch((error: any) => {
                console.error(error);
                setError(error);
                setLoading(false);
            });
    };

    return { loading, data, error, makeApiCall, resetState }
};