import {useIsAdmin} from "../../auth/utils";
import {Page} from "../../utils/Page";
import {
    alternateBackground,
    CASE_FIELDS,
    CASE_TYPE_DESIGN, CASE_TYPE_INFRINGEMENT, CASE_TYPE_OPPOSITION,
    CASE_TYPE_PATENT,
    CASE_TYPE_TRADEMARK,
    compactTable
} from "../CaseList";
import {countries} from "../../utils/countries";
import {Datagrid, DateField, Form, FormDataConsumer, FunctionField, Identifier, List, Pagination,
    RadioButtonGroupInput, RaRecord, SelectInput, TextField, useListContext, useRecordContext
} from "react-admin";
import moment from "moment";
import {
    INSTRUCTION_PAY, INSTRUCTION_WAIT, INSTRUCTION_DO_NOT_PAY
} from "./Annuities";
import {useTenantId} from "../../data/useTenantId";
import {Stack, Typography} from "@mui/material";
import {
    DesignCaseInputFields,
    OppositionInputFields,
    PatentCaseInputFields,
    TrademarkCaseInputFields
} from "../crud/CaseInputFields";
import {FieldValues, useForm} from "react-hook-form";
import {darken, lighten, styled} from '@mui/material/styles';
import {errorLight, successLight, warningLight} from "../../utils/theme";
import {
    AnnuityAbandonedField, annuityIsPristine,
    AnnuityPaidElsewhereField,
    AnnuityPaidField, AnnuityStatus,
    InstructionsSentField
} from "./utils";

export const AnnuitiesManagementPeriod = (props: any) => {
    const isAdmin = useIsAdmin();
    const tenantId = useTenantId();

    const twoMonthsQuarter = moment().add(0, "months").quarter(); // For demo
    // const twoMonthsQuarter = moment().add(2, "months").quarter();
    const nextQuarter = twoMonthsQuarter === 4 ? 1 : twoMonthsQuarter + 1; // 4th quarter + 1 is 1st quarter
    const startQuarter = moment().quarter(nextQuarter).startOf("quarter").format("YYYY-MM-DD");
    const endQuarter = moment().quarter(nextQuarter).endOf("quarter").format("YYYY-MM-DD");

    return (
        <Page title={"Annuities Management"} body={<>
            {startQuarter} - {endQuarter}
            <br/>
            <br/>
            Set your choice of action for each annuity with due date within the period.
            <br/>
            <br/>
            Explanation of the different actions:
            <br/>
            Pay: Breeze will pay the annuity on your behalf.
            <br/>
            Wait: Breeze will not pay the annuity but remind you before the grace period expires (it will appear in the
            relevant annuity period and require an action from you).
            <br/>
            Do not pay: Breeze will not pay and remove future annuities for this case. This means that you will not
            receive any reminders for this case in the future.
        </>}>
            <List
                resource={"annuities"}
                storeKey={"annuities-management"}
                sort={{field: "due_date", order: "ASC"}}
                filter={{
                    "due_date@_gte": startQuarter,
                    "due_date@_lte": endQuarter,
                    tenant_id: tenantId,
                }}
                perPage={25} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]}/>}
            >
                {/*<HandleAnnuitiesPerFamiliy></HandleAnnuitiesPerFamiliy>*/}
                <Form>
                    <FormDataConsumer>
                        {({formData, ...rest}) => (
                            <Datagrid rowSx={annuitiesManagementRowSx(formData)} bulkActionButtons={false} hover={false}
                                // sx={{
                                //     '& .RaDatagrid-rowEven:hover': {
                                //         border: '1px solid black !important',
                                //     },
                                //     '& .RaDatagrid-rowOdd:hover': {
                                //         border: '1px solid black',
                                //     },
                                // }}
                            >
                                {isAdmin && <TextField source={"tenant_name"} label={"Client"}/>}
                                <TextField source={"case_ref"}/>
                                <FunctionField
                                    label="Country"
                                    source={"country_code"}
                                    render={(record: any) => countries[record["country_code"]]}
                                />
                                <TextField source={"applicant"}/>
                                <TextField source={"application_number"}/>
                                {/*<TextField source={"catchword"} />*/}
                                <TextField source={"registration_number"}/>
                                <FunctionField
                                    label="Action name"
                                    render={(record: any) => record["title"] ? `${record["action_name"]} – ${record["title"]}` : record["action_name"]}
                                />
                                <DateField source={"due_date"}/>
                                {/*{isAdmin && <TextField source={"our_fee"} />}*/}
                                {/*{isAdmin && <TextField source={"our_fee_currency"} />}*/}
                                {/*{isAdmin && <TextField source={"official_fee"} />}*/}
                                {/*{isAdmin && <TextField source={"official_fee_currency"} />}*/}
                                {/*{isAdmin && <TextField source={"agent_fee"} />}*/}
                                {/*{isAdmin && <TextField source={"agent_fee_currency"} />}*/}
                                <FunctionField
                                    label={"Auto annuity"}
                                    render={(record: any) => record.auto_annuity ? "Yes" : "No"}
                                />
                                <TextField source={"nok_price"}/>
                                <TextField source={"usd_price"}/>
                                <AnnuityConclusionInput></AnnuityConclusionInput>
                            </Datagrid>
                        )}
                    </FormDataConsumer>
                </Form>
            </List>
        </Page>
    );
};

export const annuitiesManagementRowSx = (formData: FieldValues) => {
    return (record: any, index: number) => {
        const backgroundColor =
            (record?.id && formData[record?.id]?.conclusion === INSTRUCTION_PAY) || record?.payment_instructions_sent_at ? successLight :
                (record?.id && formData[record?.id]?.conclusion === INSTRUCTION_WAIT) ? warningLight :
                    (record?.id && formData[record?.id]?.conclusion === INSTRUCTION_DO_NOT_PAY) || record?.annuity_paid_elsewhere_at || record?.annuity_abandoned_at ? errorLight :
                        record?.id && formData[record?.id]?.conclusion === "Paid elsewhere" ? errorLight : '#fff';
        return ({
            backgroundColor: backgroundColor,
            '&:hover': {
                backgroundColor: darken(backgroundColor, 0.03),
            },
        });
    };
};


export const AnnuityConclusionInput = (props: any) => {
    const {setValue} = useForm();
    const record = useRecordContext();
    if (!record?.id) return null;

    if (!annuityIsPristine(record) && !record?.instructions_wait_sent_at) {
        return null;
    }


    return (
        <RadioButtonGroupInput source={`${record.id}.conclusion`} label={false} helperText={false} row={false}
                               choices={[
                                   {id: INSTRUCTION_PAY, name: 'Pay'},
                                   {id: INSTRUCTION_DO_NOT_PAY, name: 'Do not pay'},
                                   {id: INSTRUCTION_WAIT, name: 'Wait'},
                                   // { id: 'Paid elsewhere', name: 'Paid elsewhere' },
                                   // { id: 'Not decided', name: 'Not decided' },
                               ]}/>
    );
}

const HandleAnnuitiesPerFamiliy = (props: any) => {
    const {data, isLoading} = useListContext();
    if (isLoading) return null;

    const families = data.reduce((families: any, annuity: any) => {
        const caseRef = annuity.case_ref;
        const familyPart = caseRef.match(/P\d+/)?.[0] || "other";
        if (families[familyPart]) {
            families[familyPart].push(annuity);
        } else {
            families[familyPart] = [annuity];
        }
        return families;
    }, {});

    console.log(families);

    return (
        <Stack>
            <Typography variant="body2">
                Total views: {data.reduce((sum, post) => sum + post.id, "")}
            </Typography>
        </Stack>
    );
}
