import { BulkUpdateFormButton } from "@react-admin/ra-form-layout";
import moment from "moment";
import {DateTimeInput, SaveButton, SimpleForm, Toolbar, useListContext, useRecordContext } from "react-admin";
import {useUserId} from "../../auth/utils";


export const ConfirmInstructionsButton = () => {
    const userId = useUserId();
    const {selectedIds, data, onUnselectItems} = useListContext();
    const now = moment();
    const selected = data.filter((d: any) => selectedIds.includes(d.id));
    const selectedCaseActionIds = selected.map((d: any) => d.case_action_id);
    return (
        <BulkUpdateFormButton resource={"case_actions"} label={"Confirm instructions"} icon={<></>} selectedIds={selectedCaseActionIds}>
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Confirm instructions and inactivate"} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 400}}
                defaultValues={{
                    instructions_confirmed_by_payment_agent_by: userId,
                    instructions_confirmed_by_payment_agent_at: now,
                    inactivated_by: userId,
                    inactivated_at: now,
                }}

            >
                <DateTimeInput source={"instructions_confirmed_by_payment_agent_at"} defaultValue={now.format()} parse={(val: any) => moment(val).format()} ></DateTimeInput>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}
