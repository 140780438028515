
export const euFlagCountries = ["up", "em"];

export const INTERNATIONAL_TRADEMARK_APPLICATION = "im";
export const countries: { [key: string]: string} = {
  ar: "Argentina",
  no: "Norway",
  se: "Sweden",
  dk: "Denmark",
  fi: "Finland",
  is: "Iceland",
  gb: "Great Britain",
  ie: "Ireland",
  de: "Germany",
  fr: "France",
  it: "Italy",
  es: "Spain",
  pl: "Poland",
  tr: "Turkey",
  us: "USA",
  ca: "Canada",
  br: "Brazil",
  cl: "Chile",
  cn: "China",
  jp: "Japan",
  kr: "South Korea",
  au: "Australia",
  nz: "New Zealand",
  ru: "Russia",
  eu: "EU",
  ep: "EP (European Patent)",
  pc: "PCT Application",
  za: "South Africa",
  il: "Israel",
  in: "India",
  id: "Indonesia",
  mx: "Mexico",
  at: "Austria",
  by: "Belarus",
  be: "Belgium",
  bg: "Bulgaria",
  ch: "Switzerland",
  cr: "Costa Rica",
  cu: "Cuba",
  cy: "Cyprus",
  cz: "Czechia",
  eg: "Egypt",
  ee: "Estonia",
  gr: "Greece",
  hr: "Croatia",
  hu: "Hungary",
  li: "Liechtenstein",
  lt: "Lithuania",
  lu: "Luxembourg",
  lv: "Latvia",
  ma: "Morocco",
  mc: "Monaco",
  me: "Montenegro",
  mk: "Macedonia",
  mt: "Malta",
  nl: "The Netherlands",
  pt: "Portugal",
  ro: "Romania",
  rs: "Serbia",
  sg: "Singapore",
  si: "Slovenia",
  sk: "Slovakia",
  al: "Albania",
  sm: "San Marino",
  ph: "The Philippines",
  th: "Thailand",
  vn: "Viet Nam",
  kp: "North Korea",
  co: "Colombia",
  kh: "Cambodia",
  ge: "Georgia",
  md: "Moldova",
  tn: "Tunisia",
  tw: "Taiwan",
  "ba": "Bosnia and Herzegovina",
  "ch-li": "Switzerland & Liechtenstein",
  up: "EU (Unitary Patent)",
  ua: "Ukraine",
  sa: "Saudi Arabia",
  ap: "ARIPO",
  oa: "OAPI",
  ea: "Eurasian Patent Organization",
  ag: "Antigua and Barbuda",
  "my": "Malaysia",
  "do": "Dominican Republic",
  hk: "Hong Kong",
  em: "EU (Design)",
  ae: "United Arab Emirates",
  all: "All Countries",
  [INTERNATIONAL_TRADEMARK_APPLICATION]: "International trademark application",
};

export const countriesOptions: Array<{id: string, name: string}> =
    Object.keys(countries)
        .map(code => ({ id: code, name: countries[code] }))
        .sort((a, b) => a.name.localeCompare(b.name));

export const countriesToOptions = (countriesObject: { [key: string]: string}): Array<{id: string, name: string}> =>
    Object.keys(countriesObject)
        .map(code => ({ id: code, name: countriesObject[code] }))
        .sort((a, b) => a.name.localeCompare(b.name));
export const unitaryPatentCountries = [
  "at",
  "be",
  "bg",
  // "cy",
  // "cz",
  "de",
  "dk",
  "ee",
  "fi",
  "fr",
  // "gr",
  // "hu",
  // "ie",
  "it",
  "lt",
  "lu",
  "lv",
  "mt",
  "nl",
  "pt",
  // "ro",
  "se",
  "si",
  // "sk"
];

export const epStates: { [key: string]: string} = {
  "al": "Albania",
  "at": "Austria",
  "ba": "Bosnia and Herzegovina",
  "be": "Belgium",
  "bg": "Bulgaria",
  "cy": "Cyprus",
  "cz": "Czech Republic",
  "de": "Germany",
  "dk": "Denmark",
  "ee": "Estonia",
  "es": "Spain",
  "fi": "Finland",
  "fr": "France",
  "gb": "Great Britain",
  "ge": "Georgia",
  "gr": "Greece",
  "hr": "Croatia",
  "hu": "Hungary",
  "ie": "Ireland",
  "is": "Iceland",
  "it": "Italy",
  "kh": "Cambodia",
  "ch-li": "Switzerland & Liechtenstein",
  "lt": "Lithuania",
  "lu": "Luxembourg",
  "lv": "Latvia",
  "ma": "Morocco",
  "mc": "Monaco",
  "md": "Moldova",
  "me": "Montenegro",
  "mk": "North Macedonia",
  "mt": "Malta",
  "nl": "Netherlands",
  "no": "Norway",
  "pl": "Poland",
  "pt": "Portugal",
  "ro": "Romania",
  "rs": "Serbia",
  "se": "Sweden",
  "si": "Slovenia",
  "sk": "Slovakia",
  "sm": "San Marino",
  "tn": "Tunisia",
  "tr": "Turkey",
  "up": "EU (Unitary Patent)",
};


export const pctStates: { [key: string]: string} = {
  "ae": "United Arab Emirates",
  "ag": "Antigua and Barbuda",
  "al": "Albania",
  "am": "Armenia",
  "ao": "Angola",
  "ap": "ARIPO",
  "at": "Austria",
  "au": "Australia",
  "az": "Azerbaijan",
  "ba": "Bosnia and Herzegovina",
  "bb": "Barbados",
  "be": "Belgium",
  "bf": "Burkina faso",
  "bg": "Bulgaria",
  "bh": "Bahrain",
  "bj": "Benin",
  "bn": "Brunei Darussalam",
  "br": "Brazil",
  "bw": "Botswana",
  "by": "Belarus",
  "bz": "Belize",
  "ca": "Canada",
  "cf": "Central African Republic",
  "cg": "Congo",
  "ch": "Switzerland",
  "ci": "Cote d'Ivoire",
  "cl": "Chile",
  "cm": "Cameroon",
  "cn": "China",
  "co": "Colombia",
  "cr": "Costa Rica",
  "cu": "Cuba",
  "cv": "Capp Verde",
  "cy": "Cyprus",
  "cz": "Czech Republic",
  "de": "Germany",
  "dj": "Djibouti",
  "dk": "Denmark",
  "dm": "Dominica",
  "do": "Dominican Republic",
  "dz": "Algeria",
  "ea": "Eurasian Patent Organization",
  "ec": "Ecuador",
  "ee": "Estonia",
  "eg": "Egypt",
  "ep": "EP (European Patent)",
  "es": "Spain",
  "fi": "Finland",
  "fr": "France",
  "ga": "Gabon",
  "gb": "United Kingdom",
  "gd": "Grenada",
  "ge": "Georgia",
  "gh": "Ghana",
  "gm": "Gambia",
  "gn": "Guinea",
  "gq": "Equatorial Guinea",
  "gr": "Greece",
  "gt": "Guatemala",
  "gw": "Guinea-Bissau",
  "hn": "Honduras",
  "hr": "Croatia",
  "hu": "Hungary",
  "id": "Indonesia",
  "ie": "Ireland",
  "il": "Israel",
  "in": "India",
  "iq": "Iraq",
  "ir": "Iran",
  "is": "Iceland",
  "it": "Italy",
  "jm": "Jamaica",
  "jo": "Jordan",
  "jp": "Japan",
  "ke": "Kenya",
  "kg": "Kyrgyzstan",
  "kh": "Cambodia",
  "km": "Comoros",
  "kn": "Saint Kitts and Nevis",
  "kp": "North Korea",
  "kr": "South Korea",
  "kw": "Kuwait",
  "kz": "Kazakhstan",
  "la": "Laos",
  "lc": "Saint Lucia",
  "li": "Liechtenstein",
  "lk": "Sri Lanka",
  "lr": "Liberia",
  "ls": "Lesotho",
  "lt": "Lithuania",
  "lu": "Luxembourg",
  "lv": "Latvia",
  "ly": "Libya",
  "ma": "Morocco",
  "mc": "Monaco",
  "md": "Moldova",
  "me": "Montenegro",
  "mg": "Madagascar",
  "mk": "North Macedonia",
  "ml": "Mali",
  "mn": "Mongolia",
  "mr": "Mauritania",
  "mt": "Malta",
  "mu": "Mauritius",
  "mw": "Malawi",
  "mx": "Mexico",
  "my": "Malaysia",
  "mz": "Mozambique",
  "na": "Namibia",
  "ne": "Niger",
  "ng": "Nigeria",
  "ni": "Nicaragua",
  "nl": "Netherlands",
  "no": "Norway",
  "nz": "New Zealand",
  "oa": "OAPI",
  "om": "Oman",
  "pa": "Panama",
  "pe": "Peru",
  "pg": "Papua New Guinea",
  "ph": "Philippines",
  "pl": "Poland",
  "pt": "Portugal",
  "qa": "Qatar",
  "ro": "Romania",
  "rs": "Serbia",
  "ru": "Russia",
  "rw": "Rwanda",
  "sa": "Saudi Arabia",
  "sc": "Seychelles",
  "sd": "Sudan",
  "se": "Sweden",
  "sg": "Singapore",
  "si": "Slovenia",
  "sk": "Slovakia",
  "sl": "Sierra Leone",
  "sm": "San Marino",
  "sn": "Senegal",
  "st": "Sao Tome and Principe",
  "sv": "El Salvador",
  "sy": "Syrian Arab Republic",
  "sz": "Eswatini",
  "td": "Chad",
  "tg": "Togo",
  "th": "Thailand",
  "tj": "Tajikistan",
  "tm": "Turkmenistan",
  "tn": "Tunisia",
  "tr": "Turkey",
  "tt": "Trinidad and Tobago",
  "tz": "Tanzania",
  "ua": "Ukraine",
  "ug": "Uganda",
  "up": "EU (Unitary Patent)",
  "us": "United States",
  "uz": "Uzbekistan",
  "vc": "Saint Vincent and the Grenadines",
  "vn": "Vietnam",
  "ws": "Samoa",
  "za": "South Africa",
  "zm": "Zambia",
  "zw": "Zimbabwe",
};

export const allCountries = { ...epStates, ...pctStates, ...countries };
