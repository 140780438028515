import { FunctionComponent } from "react";

export const Flag: FunctionComponent<{countryCode: string}> = ({ countryCode }) => {
    if (countryCode?.toLowerCase() === "ep") return (
        <img
            loading="lazy"
            width="20"
            style={{verticalAlign: "middle"}}
            // src={`https://res.cloudinary.com/dhdixshqm/image/upload/w_20/v1719315116/tgjui8v59yipkjapyj2h.gif`}
            src={`https://res.cloudinary.com/dhdixshqm/image/upload/v1719315116/tgjui8v59yipkjapyj2h.gif`}
            alt="EP logo"
        />
    );


    return (
        <img
            loading="lazy"
            width="20"
            style={{verticalAlign: "middle"}}
            srcSet={`https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${countryCode?.toLowerCase()}.png`}
            alt=""
        />
    );
};
