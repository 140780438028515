import {Alert, AlertTitle, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import {triggerDateOptions} from "../../actions/triggerDate";
import CloseIcon from '@mui/icons-material/Close';
import {CloseDialogIconButton} from "../../utils/CloseDialogIconButton";
import {errorLight, successLight, warningLight} from "../../utils/theme";
import {useHasuraRequest} from "../../utils/useHasuraRequest";
import {submitPaymentInstructionsMutation} from "./submitPaymentInstructions";
import {Spinner} from "../../utils/Spinner";
import { useForm } from "react-hook-form";
import { useEffect } from "react";


export const ReviewAndSubmitInstructionsDialog = (props: any) => {
    const { loading, data, error, makeApiCall } = useHasuraRequest();

    const paid = props?.instructionsData?.pay_ids?.length;
    const wait = props?.instructionsData?.wait_ids?.length;
    const doNotPay = props?.instructionsData?.do_not_pay_ids?.length;

    console.log("Data", data);
    useEffect(() => {
        console.log("Data2", data);
        if (data) {
            props?.onInstructionsSubmitted();
        }
    }, [data]);

    useEffect(() => {
        console.log("FIRST RENDER");
    }, []);
    const onConfirm = () => {
        makeApiCall({
            query: submitPaymentInstructionsMutation,
            variables: props.instructionsData
        });
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
            <CloseDialogIconButton onClose={props.onClose}/>
            <DialogTitle>{`Submit instructions`}</DialogTitle>
            {loading && <Spinner />}
            {
                error ?
                    <DialogContent>
                        <Alert severity="error" sx={{marginBottom: 2}}>
                            <AlertTitle>Error</AlertTitle>
                            <Typography>An error occurred while trying to submit your instructions. We're very sorry for the inconvenience and will look into the issue.</Typography>
                        </Alert>
                    </DialogContent> :
                data ?
                    <DialogContent>
                        <Alert severity="success" sx={{marginBottom: 2}}>
                            <AlertTitle>Success</AlertTitle>
                            <Typography>Your instructions were submitted successfully. </Typography>
                            <Typography>We will confirm safe receipt of the instructions by email after one of our employees have had a chance to look at it (usually within a day)</Typography>
                        </Alert>
                    </DialogContent> :
                    <DialogContent>
                        <Stack spacing={4}>
                            <Box>Are you sure you want to submit the instructions?</Box>
                            <Stack direction={"row"} sx={{marginRight: 5}} spacing={2} alignItems={"center"}>
                                <Chip label={`Pay ${paid}`} sx={{ backgroundColor: successLight }}></Chip>
                                <Chip label={`Don't pay ${doNotPay}`} sx={{ backgroundColor: errorLight }}></Chip>
                                <Chip label={`Wait ${wait}`} sx={{ backgroundColor: warningLight }}></Chip>
                            </Stack>
                        </Stack>
                    </DialogContent>
            }
            {
                error ?
                    <DialogActions>
                        <Button onClick={props.onClose}>Close</Button>
                        <Button onClick={onConfirm}>Submit instructions</Button>
                    </DialogActions> :
                    data ?
                        <DialogActions>
                            <Button onClick={props.onClose}>Close</Button>
                        </DialogActions> :
                        <DialogActions>
                            <Button onClick={props.onClose}>Cancel</Button>
                            <Button onClick={onConfirm}>Submit instructions</Button>
                        </DialogActions>
            }
        </Dialog>
    );
}